import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import Joi from "joi";
import * as actions from "../Redux/Actions/index";
import axios from "axios";
import "./Signin.scss";
import { FORGET_PASSWORD, RESETLOGINCONFIG } from "./api";
import logo from '../Assets/Images/MontyMobileLogo.svg'
import { MDBBtn, MDBInput, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withTranslation } from "react-i18next"
import LoginBackground from '../Assets/Images/login-bg.png'
import { Link } from 'react-router-dom'
import montymobileLogo from '../Assets/monty-mobile-logo-white.svg'

const MODAL_OPEN_CLASS = "login";

class Forget extends Component {

  state = {
    isTablet: matchMedia("(max-width: 765px)").matches,
    isMobile: matchMedia("(max-width: 426px)").matches,
  }

  componentDidMount() {
    document.body.classList.add(MODAL_OPEN_CLASS);
    // if(localStorage.getItem("token") !== null){
    //   this.props.history.push("/dashboard");
    // }
    this.tabletMedia = matchMedia("(max-width: 765px)");
    this.mobileMedia = matchMedia("(max-width: 426px)");
    this.tabletMedia.addEventListener("change", this.setTabletMedia);
    this.mobileMedia.addEventListener("change", this.setMobileMedia);
  }

  componentWillUnmount() {
    document.body.classList.remove(MODAL_OPEN_CLASS);
    this.tabletMedia.removeEventListener("change", this.setTabletMedia);
    this.mobileMedia.removeEventListener("change", this.setMobileMedia);
  }

  state = {
    username: "",
    data: { username: "" },
    errors: {},
    forgetMessage: "",
    showError: false
  };

  schema = {
    username: Joi.string()
      .required()
      .min(5)
      .max(50)
  };

  setTabletMedia = ({ matches }) => this.setState({ isTablet: matches });
  setMobileMedia = ({ matches }) => this.setState({ isMobile: matches });

  handleChange = event => {
    // const data = { ...this.state.data };
    // data[e.currentTarget.name] = e.currentTarget.value;
    // this.setState({ data,disableSend : false}); 

    this.setState({
      [event.currentTarget.name]: event.currentTarget.value, disableSend: false
    })

  };


  validate = () => {
    const errors = {};
    let data = {}
    data = {
      username: this.state.username
    }
    const result = Joi.validate(data, this.schema, {
      abortEarly: false
    });
    if (!result.error) return null;
    for (let items of result.error.details)
      errors[items.path[0]] = items.message;

    return errors;
  };

  onSubmit = event => {
    //   const errors = this.validate();
    let formProps = {}
    formProps = {
      username: this.state.username
    }
    //   this.setState({ errors: errors || {} });

    //disable button after click
    this.setState({ disableSend: true })
    this.setState({ forgetMessage: "" });

    //   if (errors){
    //     if (this.state.username.length < 5) 
    //     { 
    //     this.setState({username:this.state.username,showError:true});
    //     return;
    //     }
    //     else 
    //     {
    //     this.setState({username:this.state.username,showError:false});
    //     }
    //   }
    //   if (!errors) {
    //     try {

    axios
      .post(FORGET_PASSWORD, formProps, RESETLOGINCONFIG)
      .then(data => {
        if (data.data.status === "success") {
          this.setState({ forgetMessage: "An email is being sent to your inbox. Please reset your password and try again.", username: '' });
          this.setState({ disableSend: false })
        } else {
          this.setState({ forgetMessage: "Invalid Username. Please contact your administrator.", username: '' });
          this.setState({ disableSend: false })
        }
      })
      .catch(error => {
        this.setState({ forgetMessage: error?.response?.data?.errors ? Object.values(error?.response?.data?.errors)[0][0] : error?.response?.data?.result?.message || "Invalid Username. Please contact your administrator.", username: '' });
        this.setState({ disableSend: false })
      });

    //   } catch (ex) {
    //     if (ex.response && ex.response.status === 400 && ex.response.status === 404) {
    //       const errors = { ...this.state.errors };
    //       errors.username = ex.response.data;
    //       this.setState({ errors, loadingButton: false });
    //       this.setState({ forgetMessage: "Something wrong happened, kindly try again" });
    //       this.setState({ disableSend: false })
    //     }
    //   }
    // }

    // if (!errors) {
    //   try {
    //     this.props.signin(formProps, () => {
    //       this.props.history.push("/dashboard");
    //     });
    //   } catch (ex) {
    //     if (ex.response && ex.response.status === 400) {
    //       const errors = { ...this.state.errors };
    //       errors.username = ex.response.data;
    //       this.setState({ errors, loadingButton: false });
    //     }
    //   }
    // }

    /* this.props.signin(formProps, () => {
      this.props.history.push("/dashboard");
    });*/
  }
  onClick() {
    window.location.href = "/";
  }

  render() {
    const { handleSubmit, t } = this.props;
    const { errors } = this.state;
    const { forgetMessage, isMobile, isTablet } = this.state;

    return (
      <div className="login">
        <MDBRow className="mx-0 login-container">
          {!isMobile && !isTablet && <MDBCol md="6" lg="7" className="left px-0">
          </MDBCol>}
              <MDBCol md="6" lg="5" middle className="right">
                <div className="center-right">
                  <form onSubmit={handleSubmit(this.onSubmit)} style={isTablet ? {} : { width: "500px" }} >
                  <MDBCol className="text-right">
                  {localStorage.getItem("logoFormat") ?
                  <div className="resizedImage"><img
                    src={localStorage.getItem("logoFormat") + "," + localStorage.getItem("uploadLogo")}
                    alt={t("logo")}
                    style={{
                      width: "auto",
                      height: "auto",
                      bottom: "0",
                      right: "0",
                      margin: "42px",
                      position: "absolute"
                    }}
                    className="logoImage"
                  /></div>  : <img
                      src={montymobileLogo}
                      alt={t("logo")}
                      style={{
                        width: "160px",
                        height: "50px",
                        bottom: "0",
                        right: "0",
                        margin: "42px",
                        position: "absolute"
                      }}

                    />
              }
              </MDBCol>
                <h1 className="px-0 pb-4 pt-0 welcome-text">{t("Forgot your")} <b>{t(" password?")}</b></h1>
                <p className="text-light">
                  {t("Please enter your account's username below and click the \"Reset Password\" button. You will receive a link to create a new password via username.")}
                </p>

                <div className="form-group">
                  {/* <Field
                    className={
                      errors["username"]
                        ? "is-invalid form-control input-underline input-lg"
                        : "form-control input-underline input-lg"
                    }
                    placeholder="Username*"
                    name="username"
                    type="text"
                    component="input"
                    onChange={this.handleChange}
                    /> */}

                  <p className="input-label m-0">Username</p>
                  <MDBInput
                    className={
                      errors["username"]
                        ? "is-invalid form-control input-underline input-lg"
                        : "form-control input-underline input-lg"
                    }
                    outline
                    value={this.state.username}
                    name="username"
                    containerClass="custom-input-container"
                    onChange={(event) => this.handleChange(event)}
                    type="text"
                    style={{ height: "50px", marginTop: "-15px" }}
                    id="username"
                    placeholder={t("Enter Username")}>
                    {this.state.showError && (
                      <div className="invalid-feedback">
                        {t("Username must be at least 5 characters long")}
                      </div>
                    )}
                  </MDBInput>
                </div>
                <div className="form-group login-but">
                  <MDBBtn disabled={this.state.disableSend} className="w-100 mx-0 p-3 login-btn mb-4 mt-3" onClick={handleSubmit(this.onSubmit)}>{t("RESET PASSWORD")}</MDBBtn>
                </div>
                <div className="form-content">
                  {forgetMessage && (<div className="form-group invalid-msg text-danger text-center">
                    {forgetMessage}
                  </div>)}
                  <div className="text-center">
                    <Link to="/"><MDBIcon icon="arrow-left" /><b className="text-danger">&nbsp; {t("Back")}</b></Link>
                  </div>
                </div>
                <div className="mt-1 pt-3">
                  <p className="text-light text-left">{t("By clicking the button, you agree to our Terms & Conditions and Privacy Policy")}</p>
                  {/* <p
                className="text-muted text-left"
              >
                {t("Already have an account?")} &nbsp;
                <Link to="/"><b className="font-weight-bold text-danger">{t("Sign in!")}</b></Link>
              </p> */}
                </div>
              </form>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({ form: "forget-pass" })
)(withTranslation("translations")(Forget));
