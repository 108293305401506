import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import Joi from "joi";
import * as actions from "../Redux/Actions/index";
import axios from "axios";
import "./Signin.scss";
import { FORGET_PASSWORD, RESETLOGINCONFIG, VERIFY_OTP } from "./api";
import logo from '../Assets/Images/MontyMobileLogo.svg'
import { MDBBtn, MDBInput, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withTranslation } from "react-i18next"
import LoginBackground from '../Assets/Images/login-bg.png'
import { Link } from 'react-router-dom'
import montymobileLogo from '../Assets/monty-mobile-logo-white.svg'
import Axios from "axios";
import { CONFIG } from "../Omni/APIs";

const MODAL_OPEN_CLASS = "login";

class OTP extends Component {

  state = {
    isTablet: matchMedia("(max-width: 765px)").matches,
    isMobile: matchMedia("(max-width: 426px)").matches,
    OTPModalfields: [
      { label: '', type: 'number', class: 'round', id: "bpin0", maxLength: 1, onChange: this.changeHandlerOTP, onKeyDown: this.formatInput },
      { label: '', type: 'number', class: 'round', id: "bpin1", maxLength: 1, onChange: this.changeHandlerOTP, onKeyDown: this.formatInput },
      { label: '', type: 'number', class: 'round', id: "bpin2", maxLength: 1, onChange: this.changeHandlerOTP, onKeyDown: this.formatInput },
      { label: '', type: 'number', class: 'round', id: "bpin3", maxLength: 1, onChange: this.changeHandlerOTP, onKeyDown: this.formatInput },
      { label: '', type: 'number', class: 'round', id: "bpin4", maxLength: 1, onChange: this.changeHandlerOTP, onKeyDown: this.formatInput },
      { label: '', type: 'number', class: 'round', id: "bpin5", maxLength: 1, onChange: this.changeHandlerOTP, onKeyDown: this.formatInput }],
  }

  componentDidMount() {
    document.body.classList.add(MODAL_OPEN_CLASS);
    // if(localStorage.getItem("token") !== null){
    //   this.props.history.push("/dashboard");
    // }
    this.tabletMedia = matchMedia("(max-width: 765px)");
    this.mobileMedia = matchMedia("(max-width: 426px)");
    this.tabletMedia.addEventListener("change", this.setTabletMedia);
    this.mobileMedia.addEventListener("change", this.setMobileMedia);
  }

  componentWillUnmount() {
    document.body.classList.remove(MODAL_OPEN_CLASS);
    this.tabletMedia.removeEventListener("change", this.setTabletMedia);
    this.mobileMedia.removeEventListener("change", this.setMobileMedia);
  }

  state = {
    username: "",
    data: { username: "" },
    errors: {},
    forgetMessage: "",
    showError: false,
    isVerified: false
  };

  schema = {
    username: Joi.string()
      .required()
      .min(5)
      .max(50)
  };

  setTabletMedia = ({ matches }) => this.setState({ isTablet: matches });
  setMobileMedia = ({ matches }) => this.setState({ isMobile: matches });

  handleChange = event => {
    // const data = { ...this.state.data };
    // data[e.currentTarget.name] = e.currentTarget.value;
    // this.setState({ data,disableSend : false}); 

    this.setState({
      [event.currentTarget.name]: event.currentTarget.value, disableSend: false
    })

  };


  validate = () => {
    const errors = {};
    let data = {}
    data = {
      username: this.state.username
    }
    const result = Joi.validate(data, this.schema, {
      abortEarly: false
    });
    if (!result.error) return null;
    for (let items of result.error.details)
      errors[items.path[0]] = items.message;

    return errors;
  };

  onSubmit = event => {
    const errors = this.validate();
    let formProps = {}
    formProps = {
      username: this.state.username
    }
    this.setState({ errors: errors || {} });

    //disable button after click
    this.setState({ disableSend: true })
    this.setState({ forgetMessage: "" });

    if (errors) {
      if (this.state.username.length < 5) {
        this.setState({ username: this.state.username, showError: true });
        return;
      }
      else {
        this.setState({ username: this.state.username, showError: false });
      }
    }
    if (!errors) {
      try {

        axios
          .post(FORGET_PASSWORD, formProps, RESETLOGINCONFIG)
          .then(data => {
            if (data.data.status === "success") {
              this.setState({ forgetMessage: "An email is being sent to your inbox. Please reset your password and try again.", username: '' });
            } else {
              this.setState({ forgetMessage: "Invalid Username. Please contact your administrator.", username: '' });
            }
          })
          .catch(error => {
            this.setState({ forgetMessage: "Invalid Username. Please contact your administrator.", username: '' });
          });

      } catch (ex) {
        if (ex.response && ex.response.status === 400 && ex.response.status === 404) {
          const errors = { ...this.state.errors };
          errors.username = ex.response.data;
          this.setState({ errors, loadingButton: false });
          this.setState({ forgetMessage: "Something wrong happened, kindly try again" });
          this.setState({ disableSend: false })
        }
      }
    }

    // if (!errors) {
    //   try {
    //     this.props.signin(formProps, () => {
    //       this.props.history.push("/dashboard");
    //     });
    //   } catch (ex) {
    //     if (ex.response && ex.response.status === 400) {
    //       const errors = { ...this.state.errors };
    //       errors.username = ex.response.data;
    //       this.setState({ errors, loadingButton: false });
    //     }
    //   }
    // }

    /* this.props.signin(formProps, () => {
      this.props.history.push("/dashboard");
    });*/
  }
  onClick() {
    window.location.href = "/";
  }

  changeHandlerOTP = e => {
    if (e.target.value === "") {
      this.setState({ [e.target.id]: "" })
    } else {
      const newVal = e.target.validity.valid
        ? e.target.value
        : this.state[e.target.id]
      this.setState({ [e.target.id]: newVal })
    }
  }

  formatInput = (e) => {
    let isNum;
    if (e.keyCode !== undefined) {
      isNum =
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 48;
    }
    return !isNum && e.preventDefault();
  }

  moveCursor = (e) => {
    var from = parseInt(e.target.id.substring(3))
    var current = e.target;
    if (current) {
        var to = "pin" + (from + 1);
        if (document.getElementById(to)) {
            var next = document.getElementById(to);
            if (current.value.length === current.maxLength) {
                if (next) {
                    next.focus();
                }
            }
        }
    }
};

verifyOTP = (e) => {
  e.preventDefault();
  const { "Login-Signature": _, ...rest } = CONFIG.headers;
    const headers = {
        ...rest,
        "Login-Signature": "fnvaN2S43O7wcQk218XOlhOpMEFV7a/OZc617BNbK4DDfyzFOiqS8AhpsNWAETKMvUIdfm2Dk5oXuKCT4OiDIA==",
        "Tenant-Key": "9ce64459-25d0-4671-a287-8626c4661905",
    }
    const putData = {
      username: this.props.location?.state?.username,
      verificationCode: this.state.pin0+""+this.state.pin1+""+this.state.pin2+""+this.state.pin3+""+this.state.pin4+""+this.state.pin5,
      generateUserToken: true,
    };
    Axios.put(VERIFY_OTP, putData, { headers }).then(({ data }) => {
      // this.props.history.push("/")
      this.setState({ isVerified: true })
    this.setState({ showLoader: true });
    
}).catch(err => { err?.response?.data?.errors ? this.displayerrors(err?.response?.data?.errors) : this.setState({ signupError: err?.response?.data?.result?.message || this.props.t("Connection Error")})  })
.finally(() => this.setState({ showLoader: false }));
}

  render() {
    const { handleSubmit, t } = this.props;
    const { errors } = this.state;
    const { forgetMessage, isMobile, isTablet, isVerified } = this.state;

    return (
      <div className="login">
        <MDBRow className="mx-0 login-container">
          {!isMobile && !isTablet && <MDBCol md="6" lg="7" className="left px-0">
           
          </MDBCol>}
          <MDBCol md="6" lg="5" middle className="right">
            <div className="center-right">
              {!isVerified && <form onSubmit={handleSubmit(this.onSubmit)} style={isTablet ? {} : { width: "500px" }} >
              <MDBCol className="text-right">
                  {localStorage.getItem("logoFormat") ?
                  <div className="resizedImage"><img
                    src={localStorage.getItem("logoFormat") + "," + localStorage.getItem("uploadLogo")}
                    alt={t("logo")}
                    style={{
                      width: "auto",
                      height: "auto",
                      bottom: "0",
                      right: "0",
                      margin: "42px",
                      position: "absolute"
                    }}
                    className="logoImage"
                  /></div>  : <img
                      src={montymobileLogo}
                      alt={t("logo")}
                      style={{
                        width: "160px",
                        height: "50px",
                        bottom: "0",
                        right: "0",
                        margin: "42px",
                        position: "absolute"
                      }}

                    />
              }
              </MDBCol>
                <h1 className="px-0 pb-4 pt-0 welcome-text">{t("Verify One Time Password")}</h1>
                <p className="text-white">
                  {t("Please enter the one time password received by email.")}
                </p>
                <div className="form-content" >
                  {forgetMessage && (<div className="form-group invalid-msg">
                    {forgetMessage}
                  </div>)}
                  <div className="form-group d-flex justify-content-center">
                    <MDBRow>
                      <MDBInput
                        label=""
                        className='round'
                        id="pin0"
                        maxLength={1}
                        onChange={this.changeHandlerOTP}
                        onKeyDown={this.formatInput}
                        onKeyUp={(e) => this.moveCursor(e)}
                      />
                      <MDBInput
                        label=""
                        className='round'
                        id="pin1"
                        maxLength={1}
                        onChange={this.changeHandlerOTP}
                        onKeyDown={this.formatInput}
                        onKeyUp={(e) => this.moveCursor(e)}
                      />
                      <MDBInput
                        label=""
                        className='round'
                        id="pin2"
                        maxLength={1}
                        onChange={this.changeHandlerOTP}
                        onKeyDown={this.formatInput}
                        onKeyUp={(e) => this.moveCursor(e)}
                      />
                      <MDBInput
                        label=""
                        className='round'
                        id="pin3"
                        maxLength={1}
                        onChange={this.changeHandlerOTP}
                        onKeyDown={this.formatInput}
                        onKeyUp={(e) => this.moveCursor(e)}
                      />
                      <MDBInput
                        label=""
                        className='round'
                        id="pin4"
                        maxLength={1}
                        onChange={this.changeHandlerOTP}
                        onKeyDown={this.formatInput}
                        onKeyUp={(e) => this.moveCursor(e)}
                      />
                      <MDBInput
                        label=""
                        className='round'
                        id="pin5"
                        maxLength={1}
                        onChange={this.changeHandlerOTP}
                        onKeyDown={this.formatInput}
                        onKeyUp={(e) => this.moveCursor(e)}
                      />
                    </MDBRow>
                  </div>
                  <div className="form-group login-but">
                    <MDBBtn disabled={this.state.disableSend} className="w-100 mx-0 p-3 login-btn mb-4 mt-1" onClick={this.verifyOTP}>{t("Verify")}</MDBBtn>
                  </div>
                  <div className="text-center">
                    <Link to="/"><MDBIcon icon="arrow-left" /><b className="text-danger">&nbsp; {t("Back")}</b></Link>
                  </div>
                </div>
                <div className="mt-1 pt-3">
                  <p className="text-light text-left">{t("By clicking the button, you agree to our Terms & Conditions and Privacy Policy")}</p>
                  <p
                    className="text-light text-left"
                  >
                    {t("Already have an account?")} &nbsp;
                    <Link to="/"><b className="font-weight-bold text-danger">{t("Sign in!")}</b></Link>
                  </p>
                </div>
              </form>}
              {isVerified && <MDBCol md="6" lg="7" middle className="right">
            <div className="center-right">
            <div style={isTablet? {} : {width:"550px"}} >
                      <h1
              className="text-center"
              style={{ marginBottom: "20px" }}
            >
              <MDBIcon icon="check-circle" size="4x" style={{paddingBottom:"25px", color:"#ED204C"}}/>
              <br/>
              <b>{t("OTP Verification Successful!")}</b>
            </h1>
            {/* <h1
              className="text-center"
              style={{ marginBottom: "20px" }}
            >
                {t("Please check your email for Verification")}
            </h1> */}
            <MDBRow className="text-center mb-3" center>
                        {this.state.signupError && <p className="text-danger">{this.state.signupError}</p>}
                        <Link to="/">
                        <MDBBtn
                          className="w-100 mx-2 p-3 login-btn mb-4 mx-5"
                          //onClick={() => this.goBackToLogin()}
                        >
                          {/* <span >{t("Go Back to Sign In")}</span> */}
                          <span >{t("Go to Sign In")}</span>
                        </MDBBtn>
                        </Link>
                      </MDBRow>
            </div>
            </div>
            </MDBCol>}
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({ form: "forget-pass" })
)(withTranslation("translations")(OTP));
