import React, { Component, Suspense, lazy } from "react";
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from "react-router-dom";
import SignIn from "./Authentication/SignIn";
import Forget from "./Authentication/Forget";
import ResetPassword from "./Authentication/ResetPassword";
import OTP from "./Authentication/OTP";

// Higher order function for checking authentication: https://medium.com/@leonardobrunolima/react-tips-higher-order-components-ac9c758050bf
import RequireAuth from "./Authentication/RequireAuth";

import PaySuccess from "../src/Omni/Containers/PaymentResponse/PaySuccess";
import PayCancel from "../src/Omni/Containers/PaymentResponse/PayCancel";

// The Main path name of the Omni web application
// import { OmniPORTAL_PATH_NAME } from "./Omni/APIs/index"

// Omni routes
const OmniNavigationRouter = lazy(() => import("./Omni/HOCs/NavigationRouter"));

// https://stackoverflow.com/questions/45373742/detect-route-change-with-react-router
// We need to detect route changes across all the pages, so that when the user is inside a service and tries to go to dashboard
// We force him back to the service. He can only go to the dashboard if follows the specified rules.
// Create history object
// var createHistory = require("history").createBrowserHistory; 
// const history = createHistory();


class CombinedRoutes extends Component {

    constructor() {
        super();
        this.tokenHandler = this.tokenHandler.bind(this);
    }

    componentDidMount(){
        const { history, token } = this.props;
        const { pathname } = window.location;
        if (token && (pathname === "/" || pathname === "/forgot-password" || pathname === "/reset-password")) history.push("/dashboard");
        // this.detachRouteListener = history.listen(({ pathname }) => { 
        //     if (token && (pathname === "/" || pathname === "/forgot-password" || pathname === "/reset-password")) history.push("/dashboard");
        // });
    }

    componentWillUnmount(){
        // this.detachRouteListener();
    }

    RouteConstructor(path, component, key, exact) {
        return <Route path={path} exact={exact} component={RequireAuth(component)} key={key} />
    }

    tokenHandler(){
        // all the tokens should have the following details:
        // 1- what apps are accessible for the token holder
        // 2- is the dashboad accessible for the token holder
        
    }

    render() {
        const routes = [
            {   
                path: "/",
                component: OmniNavigationRouter,
                key: "/",
            }
        ]
        const finalRoutes = routes.map(route => {
            return this.RouteConstructor(route.path, route.component, route.key, route.exact)
        })
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route path={`/`} exact component={SignIn} key={`/`} />
                    <Route path={`/forgot-password`} exact component={Forget} key={`/forgot-password`} />
                    <Route path={`/reset-password/`} component={ResetPassword} key={`/reset-password`} />
                    <Route path={`/otp`} exact component={OTP} key={`/otp`} />

                    <Route path={`/paysuccess`} component={PaySuccess} key={`/paysuccess`} />
                    <Route path={`/paycancel`} component={PayCancel} key={`/paycancel`} />
                    {finalRoutes.map(route => { return route })}
                </Switch>
            </Suspense>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    token: state.authentication ? state.authentication.token : "",
    mainToken: state.authentication ? state.authentication.mainToken : ""
})

export default connect(mapStateToProps, null)(withRouter(CombinedRoutes));