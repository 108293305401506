import { combineReducers } from 'redux';
import authentication from "./authentication";
import language from "./language";
import access_level from "./access_level";
import operators from "../../Omni/Redux/Bussiness-Partner/Operator/Operator.reducer";
import masterInfo from "../../Omni/Redux/Master-Info/Master-Info.reducer";
import whitelabeling from "../../Omni/Redux/Bussiness-Partner/Operator/White-Labeling/White-Labeling.reducer"
import pagesReducer from "./pages";
import stateReducer from "./state";

export default combineReducers({
    authentication,
    language,
    access_level,
    operators,
    whitelabeling,
    pagesReducer,
    masterInfo,
    stateReducer
})