import OperatorActionTypes from './Operator.types';
import Steps from '../../../Components/Utils/StepsData';
const INITIAL_STATE = {
    operators: [],
    operator: [],
    operatorInfo: {},
    configuration: {},
    billingInfo: {},
    selectedOperator: [],
    Steps: Steps,
    activeIndex: 0,
    isOperatorCreated: false,
    editOperator:{},
    showOperatorList: true,
    showAddOperator: false,
    showEditOperator: false,    
    showWhiteLabeling: false,
    showHistory: false
}
export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case OperatorActionTypes.FETCH_OPERATOR:
            return {...state, showOperatorList: true, operators:action.payload}    
        case OperatorActionTypes.FETCH_OPERATOR_BY_ID:
            return {...state, activeIndex: 0, editOperator:action.payload}
        case OperatorActionTypes.ADD_OPERATOR_INFO:
            return {...state, activeIndex: 1, operatorInfo: action.payload}
        case OperatorActionTypes.ADD_CONFIG:
            return {...state, activeIndex: 2, configuration: action.payload}
        case OperatorActionTypes.ADD_OPERATOR_CLICK:
            return {...state, showAddOperator: action.payload, activeIndex: 0, showOperatorList: false}
        case OperatorActionTypes.ADD_BILLING_INFO:
            return {...state, activeIndex: 2, billingInfo: action.payload}
        case OperatorActionTypes.CREATE_OPERATOR:
            return {...state, activeIndex: 3, isOperatorCreated: true, selectedOperator: action.payload}
        case OperatorActionTypes.UPDATE_OPERATOR:
            return {...state, activeIndex: 3, isOperatorCreated: true, selectedOperator: action.payload}
        case OperatorActionTypes.GO_TO_BACK:
            return {...state, activeIndex: action.payload}
        case OperatorActionTypes.SHOW_WHITELABELING:
            return {...state, showWhiteLabeling: action.payload, showOperatorList: false}
        case OperatorActionTypes.SHOW_HISTORY:
            return {...state, showHistory: action.payload, showOperatorList: false}
        case OperatorActionTypes.SHOW_EDIT_OPERATOR:
            return {...state, showEditOperator: action.payload, showOperatorList: false}
        case OperatorActionTypes.CANCLE_CLICK:
            return {...state, showWhiteLabeling: false, showAddOperator: false, showHistory: false, showEditOperator:false, showOperatorList: true}
        default:
            return state;
    }
    
}