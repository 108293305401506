const steps = [
    {
        "id": "1",
        "label" : "Operator Info",
        "completedClass": ""
    },
    {
        "id": "2",
        "label" : "Configuration",
        "completedClass": ""
    },
    {
        "id": "3",
        "label" : "Billing Info",
        "completedClass": ""
    }
];

export default steps;