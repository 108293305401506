import { MDBCol, MDBContainer, MDBModal, MDBModalBody, MDBRow } from 'mdbreact';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Success from "./../../assets/successful.gif";
import "./PaymentResponse.scss";

class PaySuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { t } = this.props;

        return (
            <MDBContainer>
                <div className='success'>
                    <MDBModal isOpen={true}>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol size="12" className="d-flex justify-content-center">
                                    <img src={Success} alt='Success' width="100px"/>
                                </MDBCol>
                                <MDBCol size="12" className="my-2 d-flex justify-content-center">
                                    <p className='font-weight-bold title'>{t("Payment Successful")}</p>
                                </MDBCol>
                                <MDBCol size="12" className="d-flex justify-content-center">
                                    <p className='sub-text'>{t("Your payment has been successfully paid. Thank you for choosing MontyPay")}</p>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                </div>
            </MDBContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.authentication ? state.authentication.token : "",
});

export default connect(mapStateToProps, null)(withTranslation("translations")(PaySuccess));