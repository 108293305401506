import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './Redux/Reducers';

// Localization package
//import i18n from './i18n-config/i18n';

//redux-persist package
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';

// my own middleware
import { onRehydrationMiddleware } from "./Redux/Middlewares/RehydrationMiddleware";

// CSS & Styling 
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import './index.scss'
import "rc-time-picker/assets/index.css";

// Portal Css
import './Assets/Css/portal.scss'

// App was imported at the end because it must be imported after the styles, if you put it above the styles import then some components in the MDBReact will fail
import App from './App';

import registerServiceWorker from './registerServiceWorker';

// configuration for the Redux-Persist dependency
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['form', 'operators','masterInfo', 'whitelabeling'] // blacklist means that I don't want certain objects of the state to be persisted
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

// if redux extension is available then we are going to apply it, otherwise use the default compose
// we will also be enabling Actions Stack Trace for better understanding of where the actions have been called from
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25 }) : compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk, onRehydrationMiddleware)));
// engulfing our entire store with the persistor functionality
const persistor = persistStore(store)


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
                <App />
        </PersistGate>
    </Provider>
    , document.getElementById('root')
);

registerServiceWorker();