const initialState = {
    stateChanged: false
};

const stateReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case "STATE_CHANGED":
            return { stateChanged: payload };
        case "CLEAR_STATE":
            return { stateChanged: false };
        default:
            return state;
    }
}

export default stateReducer;