
const initialState = {
    token: "",
    expiry: -1
}

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return {
                // the reason am storing action.token.access_token again in a key named 'loginToken' is that because on Action of type REFRESH_TOKEN
                // we are going to update the the value of 'token', so loginToken will have the initial data on login which contains far more info
                // because the refresh token has less details (like the pages access are not in the refresh token)
                ...state,
                token: action.token.access_token,
                expiry: action.token.access_token_expires_in,
                loginToken: action.token.access_token, 
                mainToken: "" // in case he came from the dashboard we want to make sure we wipe out the mainToken
            }
        case 'SIGN_OUT':
            return {
                ...state,
                token: "",
                expiry: -1,
                loginToken: "",
                mainToken: ""
            }
        case "UPDATE_EXPIRY":
            return {
                ...state,
                expiry: action.payload || -1
            };
        case 'NON_REGISTERED_USER':
            return {
                ...state,
                token: "",
                expiry: -1
            }
        case 'REFRESH_TOKEN':
            return {
                ...state,
                token: action.token.access_token,
                expiry: action.token.access_token_expires_in
            }
        case 'MAIN_SIGN_IN':
            return {
                ...state,
                token: "",
                expiry: -1,
                loginToken: "",
                mainToken: action.token.access_token,
                mainTokenExpiry: action.token.access_token_expires_in
            }
        default: 
            return state
    }
}

export default authenticationReducer