import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import Joi from "joi";
import * as actions from "../Redux/Actions/index";
import axios from "axios";
import "./Signin.scss";
import { RESET_PASSWORD, RESETLOGINCONFIG } from "./api";
import logo from '../Assets/Images/MontyMobileLogo.svg'
import { MDBBtn, MDBInput, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withTranslation } from "react-i18next"
import LoginBackground from '../Assets/Images/login-bg.png'
import { Link } from 'react-router-dom'
import montymobileLogo from '../Assets/monty-mobile-logo-white.svg'

const MODAL_OPEN_CLASS = "login";

class Forget extends Component {

  state={
    isTablet: matchMedia("(max-width: 765px)").matches,
    isMobile: matchMedia("(max-width: 426px)").matches,
  }

  componentDidMount() {
    document.body.classList.add(MODAL_OPEN_CLASS);
    // if(localStorage.getItem("token") !== null){
    //   this.props.history.push("/dashboard");
    // }
    this.tabletMedia = matchMedia("(max-width: 765px)");
    this.mobileMedia = matchMedia("(max-width: 426px)");
    this.tabletMedia.addEventListener("change", this.setTabletMedia);
    this.mobileMedia.addEventListener("change", this.setMobileMedia);
  }

  componentWillUnmount() {
    document.body.classList.remove(MODAL_OPEN_CLASS);
    this.tabletMedia.removeEventListener("change", this.setTabletMedia);
    this.mobileMedia.removeEventListener("change", this.setMobileMedia);
  }

  state = {
    username: "",
    data: { username: "" },
    errors: {},
    forgetMessage: "",
    showError:false
  };

  schema = {
    username: Joi.string()
      .required()
      .min(5)
      .max(50)
  };

  setTabletMedia = ({ matches }) => this.setState({ isTablet: matches });
  setMobileMedia = ({ matches }) => this.setState({ isMobile: matches });

  handleChange = event=> {
    // const data = { ...this.state.data };
    // data[e.currentTarget.name] = e.currentTarget.value;
    // this.setState({ data,disableSend : false}); 
 
      this.setState({
          [event.currentTarget.name]: event.currentTarget.value ,disableSend : false
      })
  
  };

  onSubmit = event => {

    this.setState({ disableSend: true })
    this.setState({ forgetMessage: "" });

    const errors = {};

    if (!this.state.userName) {
      errors.userName = true;
      this.setState({ disableSend: false })
    }
    if (!this.state.password){
      errors.password = true;
      this.setState({ disableSend: false })
    }
    if (!this.state.ConfirmPassword || this.state.password !== this.state.ConfirmPassword){
      errors.ConfirmPassword = true;
      this.setState({ disableSend: false })
    }
    
    this.setState({ errors });
    if (errors.userName || errors.password || errors.ConfirmPassword) 
      return;

    var parsedUrl = new URL(window.location.href)
    const token = parsedUrl.search
    var resetToken = token.substr(7);
    let data = {}
    data = {
      userName: this.state.userName,
      password: this.state.password,
      ConfirmPassword: this.state.ConfirmPassword
    }
    var data_to_send = data;

    let headerConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Tenant-Key": "9ce64459-25d0-4671-a287-8626c4661905",
        "Content-Type": "application/json",
        "Reset-Token": resetToken
      }
    }

    data_to_send.newPassword = this.state.password;

    // if (this.state.ConfirmPassword) {
    //   if (this.state.password !== this.state.ConfirmPassword) {
    //     this.setState({ showConfirmPassError: true })
    //   }
    //   else {
    //     this.setState({ showConfirmPassError: false })
    //   }
    // }
    // if (this.state.password) {
    //   if (!this.isPassword(this.state.password)) {
    //     this.setState({ password: this.state.password, emptyPassword: true });
    //     return;
    //   }
    //   else {
    //     this.setState({ password: this.state.password, emptyPassword: false });
    //   }
    // }
    // const errors = this.validate();

    // this.setState({ errors: errors || {} });

    let message = ""

    // if (!errors) {
      try {

        axios.post(RESET_PASSWORD, data_to_send, headerConfig)
          .then(data => {
            if (data.data.status === "success") {
              this.setState({ message: "success", errorMessage: data.data.result, confirmed: true, password: '', ConfirmPassword: '', userName: '', errors: {}, disableSend: false })

            } else {
              this.setState({ message: "failed", errorMessage: data.data.result.message, password: '', ConfirmPassword: '', userName: '', errors: {}, disableSend: false })
 
            }
          })
          .catch(ex => {
            if (ex.response && ex.response.status === 400 || ex.response && ex.response.status === 404 ||
              ex.response && ex.response.status === 401 || ex.response && ex.response.status === 500) {
              this.setState({ message: "failed", errorMessage: ex?.response?.data?.errors? Object.values(ex?.response?.data?.errors)[0][0] : ex?.response?.data?.result?.message || "Connection Error",disableSend: false })
            }

          });

      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.username = ex.response.data;
          this.setState({ errors, loadingButton: false });
        }
      }
    // }

    /* this.props.password(formProps, () => {
      this.props.history.push("/dashboard");
    });*/
  };

  onClick(){
    window.location.href = "/";
  }

  render() {
    const { handleSubmit, t } = this.props;
    const { errors } = this.state;
    const { errorMessage, isMobile, isTablet } = this.state;

    return (
      <div className="login">
        <MDBRow className="mx-0 login-container">
        {!isMobile && !isTablet && <MDBCol md="6" lg="5" className="left px-0">
           
           </MDBCol>}
           <MDBCol md="6" lg="7" middle className="right">
          <div className="center-right">
            <form onSubmit={handleSubmit(this.onSubmit)} style={isTablet? {} : {width:"500px"}} >
            <MDBCol className="text-right">
                  {localStorage.getItem("logoFormat") ?
                  <div className="resizedImage"><img
                    src={localStorage.getItem("logoFormat") + "," + localStorage.getItem("uploadLogo")}
                    alt={t("logo")}
                    style={{
                      width: "auto",
                      height: "auto",
                      bottom: "0",
                      right: "0",
                      margin: "42px",
                      position: "absolute"
                    }}
                    className="logoImage"
                  /></div>  : <img
                      src={montymobileLogo}
                      alt={t("logo")}
                      style={{
                        width: "160px",
                        height: "50px",
                        bottom: "0",
                        right: "0",
                        margin: "42px",
                        position: "absolute"
                      }}

                    />
              }
              </MDBCol>
              <h1 className="px-0 pb-5 pt-0 welcome-text">{t("Reset your")} <b>{t(" password")}</b></h1>
            <p className="text-white">
            {t("Please fill the below from to reset your password.")}
            </p>
              
                <div className="form-group">
                  {/* <Field
                    className={
                      errors["username"]
                        ? "is-invalid form-control input-underline input-lg"
                        : "form-control input-underline input-lg"
                    }
                    placeholder="Username*"
                    name="username"
                    type="text"
                    component="input"
                    onChange={this.handleChange}
                    /> */}

<div className="form-group">
                    <MDBInput
                      value={this.state.userName}
                      className={
                        errors["userName"]
                          ? "is-invalid form-control input-underline input-lg"
                          : "form-control input-underline input-lg"
                      }
                      containerClass="custom-input-container"
                      style={{ margin: "0" }}
                      placeholder="Username*"
                      name="userName"
                      type="text"
                      component="input"
                      outline
                      onChange={(event) => this.handleChange(event)}
                    >
                      {!this.state.userName && <div className="invalid-feedback">
                        {t("Please enter a username")}
                      </div>}
                    </MDBInput>
                  </div>
                  <div className="form-group">
                    <MDBInput
                      className={
                        errors["password"]
                          ? "is-invalid form-control input-underline input-lg"
                          : "form-control input-underline input-lg"
                      }
                      containerClass="custom-input-container"
                      style={{ margin: "0" }}
                      autoComplete="new-password"
                      placeholder="Password*"
                      name="password"
                      type="password"
                      component="input"
                      outline
                      onChange={(event) => this.handleChange(event)}
                      value={this.state.password}
                    >
                      {!this.state.password && (<div className="invalid-feedback">
                        {t("Please enter a new password")}
                      </div>)}
                      {/* {!this.state.confirmed && this.state.emptyPassword && !this.isPassword(this.state.password) && (<small>
                        Passwords must have at least 8 characters and contain the following: uppercase letters, lowercase letters, numbers, and symbols.
                      </small>)} */}
                    </MDBInput>
                  </div>

                  <div className="form-group">
                    <MDBInput
                      value={this.state.ConfirmPassword}
                      className={
                        errors["ConfirmPassword"]
                          ? "is-invalid form-control input-underline input-lg"
                          : "form-control input-underline input-lg"
                      }
                      containerClass="custom-input-container"
                      style={{ margin: "0" }}
                      placeholder="Confirm Password*"
                      name="ConfirmPassword"
                      type="password"
                      component="input"
                      outline
                      onChange={(event) => this.handleChange(event)}
                    >
                      {this.state.password !== this.state.ConfirmPassword && 
                        <div className="invalid-feedback">
                          {t("Passwords do not match")}
                        </div>
                      }
                    </MDBInput>
                  </div>
                </div>
                <div className="form-group login-but">
                  <MDBBtn disabled={this.state.disableSend}  className="w-100 mx-0 p-3 login-btn mb-4 mt-3" onClick={handleSubmit(this.onSubmit)}>{t("RESET PASSWORD")}</MDBBtn>
                </div>
                <div className="form-content">
                {errorMessage && (<div className="form-group invalid-msg text-danger text-center">
                  {errorMessage}
                </div>)}
                <div className="text-center">
                <Link to="/"><MDBIcon icon="arrow-left" /><b className="text-danger">&nbsp; {t("Back")}</b></Link>
                </div>
              </div>
              <div className="mt-5 pt-5">
              <p className="text-light text-left">{t("By clicking the button, you agree to our Terms & Conditions and Privacy Policy")}</p>
              {/* <p
                className="text-muted text-left"
              >
                {t("Already have an account?")} &nbsp;
                <Link to="/"><b className="font-weight-bold text-danger">{t("Sign in!")}</b></Link>
              </p> */}
              </div>
            </form>
            </div>
      </MDBCol>
      </MDBRow>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({ form: "forget-pass" })
)(withTranslation("translations")(Forget));

// import React, { Component } from "react";
// import PropTypes from "prop-types"
// import { Field, reduxForm } from "redux-form";
// import { compose } from "redux";
// import { connect } from "react-redux";
// import Joi from "joi";
// import * as actions from "../Redux/Actions/index";
// import axios from "axios";
// import logo from '../Assets/Images/monty-logo.svg'
// import "./Signin.scss";
// import { RESET_PASSWORD } from "./api";
// import { MDBInput } from 'mdbreact';
// import { withTranslation } from "react-i18next"

// const MODAL_OPEN_CLASS = "login";

// class ResetPassword extends Component {
//   componentDidMount() {
//     document.body.classList.add(MODAL_OPEN_CLASS)

//   }

//   componentWillUnmount() {
//     document.body.classList.remove(MODAL_OPEN_CLASS);
//   }
//   static propTypes = {
//     password: PropTypes.func
//   };
//   state = {
//     userName: "",
//     password: "",
//     data: { userName: "", password: "" },
//     errors: {},
//     message: "",
//     emptyPassword: false,
//     showConfirmPassError: false
//   };

//   schema = {
//     userName: Joi.string()
//       .required()
//       .min(5)
//       .max(50),
//     password: Joi.string()
//       .regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[\!\@\#\$\%]).{6,}$/)
//       .required()
//       .min(8)
//       .max(16),
//    // ConfirmPassword: Joi.any().valid(Joi.ref('password')).required().options({ language: { any: { allowOnly: 'must match password' } } })
//   };

//   isPassword(data) {
//     var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[\!\@\#\$\%]).{6,}$/;
//     var password = data;
//     let isPassword;
//     isPassword = regex.test(password);
//     return isPassword;
//   }

//   handleChange = e => {
//     // const data = { ...this.state.data };
//     // data[e.currentTarget.name] = e.currentTarget.value;
//     // this.setState({ data });

//     this.setState({
//       [e.currentTarget.name]: e.currentTarget.value
//     })
//   };

//   validate = () => {

//     if (this.state.password === "") {
//       this.setState({ emptyPassword: false })
//     } else {
//       this.setState({ emptyPassword: true })
//     }

//     const errors = {};
//     let data = {}
//     data = {
//       userName: this.state.userName,
//       password: this.state.password,
//       ConfirmPassword: this.state.ConfirmPassword
//     }
//     //  const { userName, password ,ConfirmPassword} = this.state; 
//     const result = Joi.validate(data, this.schema, {
//       abortEarly: false
//     });
//     if (!result.error) return null;
//     for (let items of result.error.details)
//       errors[items.path[0]] = items.message;

//     return errors;
//   };

//   onSubmit = event => {

//     const errors = {};

//     if (!this.state.userName) 
//       errors.userName = true;
//     if (!this.state.password)
//       errors.password = true;
//     if (!this.state.ConfirmPassword || this.state.password !== this.state.ConfirmPassword)
//       errors.ConfirmPassword = true;
    
//     this.setState({ errors });
//     if (errors.userName || errors.password || errors.ConfirmPassword) 
//       return;

//     var parsedUrl = new URL(window.location.href)
//     const token = parsedUrl.search
//     var resetToken = token.substr(7);
//     let data = {}
//     data = {
//       userName: this.state.userName,
//       password: this.state.password,
//       ConfirmPassword: this.state.ConfirmPassword
//     }
//     var data_to_send = data;

//     let headerConfig = {
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//         "Access-Control-Allow-Headers":
//           "Origin, X-Requested-With, Content-Type, Accept, Authorization",
//         "Tenant-Key": "1f23e034-5d97-477b-8fff-d795c5fae13e",
//         "Content-Type": "application/json",
//         "Reset-Token": resetToken
//       }
//     }

//     data_to_send.newPassword = this.state.password;

//     // if (this.state.ConfirmPassword) {
//     //   if (this.state.password !== this.state.ConfirmPassword) {
//     //     this.setState({ showConfirmPassError: true })
//     //   }
//     //   else {
//     //     this.setState({ showConfirmPassError: false })
//     //   }
//     // }
//     // if (this.state.password) {
//     //   if (!this.isPassword(this.state.password)) {
//     //     this.setState({ password: this.state.password, emptyPassword: true });
//     //     return;
//     //   }
//     //   else {
//     //     this.setState({ password: this.state.password, emptyPassword: false });
//     //   }
//     // }
//     // const errors = this.validate();

//     // this.setState({ errors: errors || {} });

//     let message = ""

//     // if (!errors) {
//       try {

//         axios.post(RESET_PASSWORD, data_to_send, headerConfig)
//           .then(data => {
//             if (data.data.status === "success") {
//               this.setState({ message: "success", errorMessage: data.data.result, confirmed: true, password: '', ConfirmPassword: '', userName: '', errors: {} })

//             } else {
//               this.setState({ message: "failed", errorMessage: data.data.result.message, password: '', ConfirmPassword: '', userName: '', errors: {} })

//             }
//           })
//           .catch(ex => {
//             if (ex.response && ex.response.status === 400 || ex.response && ex.response.status === 404 ||
//               ex.response && ex.response.status === 401 || ex.response && ex.response.status === 500) {
//               this.setState({ message: "failed", errorMessage: ex.response.data.result.message })
//             }

//           });

//       } catch (ex) {
//         if (ex.response && ex.response.status === 400) {
//           const errors = { ...this.state.errors };
//           errors.username = ex.response.data;
//           this.setState({ errors, loadingButton: false });
//         }
//       }
//     // }

//     /* this.props.password(formProps, () => {
//       this.props.history.push("/dashboard");
//     });*/
//   };
//   render() {
//     const { t } = this.props
//     const { handleSubmit } = this.props;
//     const { errors, message, errorMessage } = this.state;

//     return (
//       <div>

//         <div className="login-page">
//           <div className="row justify-content-md-center">
//             <div className="col-md-4">
//               <a href="/">
//                 <img
//                   src={logo}
//                   width="150px"
//                   alt="Logo"
//                   className="user-avatar"
//                 />
//               </a>
//               <h1>{t("Reset Password")}</h1>
//               <form onSubmit={handleSubmit(this.onSubmit)}>
//                 <div className="form-content">

//                   {message == "success" && (
//                     <div className="form-group invalid-msg">
//                       <p>{errorMessage}<a className="login-butt" href='/'>{t("Log In")}</a></p>
//                     </div>
//                   )}

//                   {message == "failed" && (
//                     <div className="form-group invalid-msg">
//                       <p>{errorMessage}</p>
//                     </div>
//                   )}

//                   <div className="form-group">
//                     <MDBInput
//                       value={this.state.userName}
//                       className={
//                         errors["userName"]
//                           ? "is-invalid form-control input-underline input-lg"
//                           : "form-control input-underline input-lg"
//                       }
//                       style={{ margin: "0" }}
//                       placeholder="Username*"
//                       name="userName"
//                       type="text"
//                       component="input"
//                       onChange={(event) => this.handleChange(event)}
//                     >
//                       {!this.state.userName && <div className="invalid-feedback">
//                         {t("Please enter a username")}
//                       </div>}
//                     </MDBInput>
//                   </div>
//                   <div className="form-group">
//                     <MDBInput
//                       className={
//                         errors["password"]
//                           ? "is-invalid form-control input-underline input-lg"
//                           : "form-control input-underline input-lg"
//                       }
//                       style={{ margin: "0" }}
//                       placeholder="Password*"
//                       name="password"
//                       type="password"
//                       component="input"
//                       onChange={(event) => this.handleChange(event)}
//                       value={this.state.password}
//                     >
//                       {!this.state.password && (<div className="invalid-feedback">
//                         {t("Please enter a new password")}
//                       </div>)}
//                       {/* {!this.state.confirmed && this.state.emptyPassword && !this.isPassword(this.state.password) && (<small>
//                         Passwords must have at least 8 characters and contain the following: uppercase letters, lowercase letters, numbers, and symbols.
//                       </small>)} */}
//                     </MDBInput>
//                   </div>

//                   <div className="form-group">
//                     <MDBInput
//                       value={this.state.ConfirmPassword}
//                       className={
//                         errors["ConfirmPassword"]
//                           ? "is-invalid form-control input-underline input-lg"
//                           : "form-control input-underline input-lg"
//                       }
//                       style={{ margin: "0" }}
//                       placeholder="Confirm Password*"
//                       name="ConfirmPassword"
//                       type="password"
//                       component="input"
//                       onChange={(event) => this.handleChange(event)}
//                     >
//                       {this.state.password !== this.state.ConfirmPassword && 
//                         <div className="invalid-feedback">
//                           {t("Passwords do not match")}
//                         </div>
//                       }
//                     </MDBInput>
//                   </div>

//                 </div>
//                 {message != "success" && (<button className="btn rounded-btn">{t("Send")}</button>)}
//                 {message == "success" && (<button disabled={true} className="btn rounded-btn">{t("Send")}</button>)}
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return { errorMessage: state.authentication.errorMessage };
// }


// export default compose(
//   connect(
//     mapStateToProps,
//     actions
//   ),
//   reduxForm({ form: "password" })
// )(withTranslation("translations")(ResetPassword));