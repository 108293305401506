export const API_URL_IDENTITY = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/identity/api/v1" : "https://omni-api-dev.montylocal.net/identity/api/v1";

// export const API_URL_IDENTITY = "https://omni-api-dev.montylocal.net/identity/api/v1"

export const token = "bearer ";

export const API_URL_CMS = "";
export const OmniPORTAL_PATH_NAME = "Omni";

export const API_HOST = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net" : "https://omni-api-dev.montylocal.net";

export const API_URL_OMNI = API_HOST + "/omni-common/api";
export const API_URL_OMNI_1 = API_HOST + "/omni-common/api";
export const API_URL_OMNI_2 = API_HOST + "/campaign/api/v1";
export const API_URL_OMNI_3 = API_HOST + "/reporting/api";
export const API_URL_OMNI_4 = API_HOST + "/dnd/api";
export const API_URL_OMNI_9 = API_HOST + "/omni-rate/api";
export const API_URL_OMNI_6 = API_HOST + "/omni-payment/api";
export const API_URL_OMNI_7 = API_HOST + "/black-list/api";
export const API_URL_OMNI_62 = API_HOST + "/campaign/api/v1.0";
export const API_URL_OMNI_NOTIF = API_HOST + "/pushnotifi/api";


// new urls
export const API_URL_ACCOUNTS = API_HOST + "/registration/api";  // for /accounts, /resellers, /users, /vendors
export const API_URL_APIS = API_HOST + "/apis/api";  // for /api
export const API_URL_PHONEBOOK = API_HOST + "/phonebook/api";  // for /contacts, /groups
export const API_URL_BILLING = API_HOST + "/billing/api";  // for /balances, /invoices, /Rate, /RateException, /PaymentTypes, /PaymentStatus, /payment, /PaymentAdmin, /PaymentNotification
export const API_URL_WHATSAPP_TEMP = API_HOST + "/whatsapptemp/api";  //for whatsapp templates
export const API_URL_WHATSAPP = API_HOST + "/whatsmessage/api";  //for whatsapp
export const API_URL_WHATSAPP_CHAT = API_HOST + "/whatsmessage"

export const CONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    Authorization: token,
    "Content-Type": "application/json",
    "Tenant-Key": 1,
  }
}

export const API_ALL_USER = API_URL_ACCOUNTS + "/Users"
export const ADD_USER = API_URL_ACCOUNTS + "/Users"
export const EDIT_USER = API_URL_ACCOUNTS + "/Users"
export const API_USER_INFO = API_URL_IDENTITY + "/Account"
export const EDIT_USER_INFO = API_URL_ACCOUNTS + "/accounts/AccountUpdate"
export const API_USER_TRACKING = `${API_URL_ACCOUNTS}/users/Tracking`
export const API_USER_LOG_HISTORY = `${API_URL_IDENTITY}/LogHistory`
export const API_TOGGLE_USER_ACTIVATION = `${API_URL_ACCOUNTS}/Users/ToggleActivation`
export const API_TOGGLE_ACCOUNT_ACTIVATION = API_URL_ACCOUNTS + "/accounts/ToggleAccount"

//load data on login page
export const GET_WEBSITE_INFO = `${API_URL_ACCOUNTS}/Resellers/Info`;

// The Api route that responds with the access rights for each page
export const ACCESS_RIGHTS = API_URL_IDENTITY + "/Pages/Access"
export const GET_PERMISSIONS = `${API_URL_IDENTITY}/Permissions`
export const GET_ROLES = `${API_URL_ACCOUNTS}/accounts/RolesByAdmin`
export const GET_ALL_ROLES = `${API_URL_ACCOUNTS}/accounts/AllRoles`
export const ADD_ROLE = `${API_URL_ACCOUNTS}/accounts/Roles`
export const DELETE_ROLE = role => `${API_URL_ACCOUNTS}/accounts/Roles?roleName=${role}`
export const GET_PAGES = `${API_URL_IDENTITY}/Pages`
export const EDIT_PAGE = `${API_URL_IDENTITY}/Pages`
export const DELETE_PAGE = pageID => `${API_URL_IDENTITY}/Pages/${pageID}`
export const GET_PAGES_BY_ROLENAME = roleName => `${API_URL_IDENTITY}/Pages/Details?roleName=${roleName}`
export const ASSIGN_PAGES_TO_ROLENAME = `${API_URL_IDENTITY}/Pages/Details`
export const ADD_PAGE_TO_ROLENAME = `${API_URL_IDENTITY}/Pages/Details`
// export const GET_PASSWORD_POLICY = `${API_URL_IDENTITY}/Tenants/PasswordPolicy`
// export const UPDATE_PASSWORD_POLICY = `${API_URL_IDENTITY}/Tenants/PasswordPolicy`
export const GET_PASSWORD_POLICY = `${API_URL_IDENTITY}/Auth/passwordconfig` //new identity apis for password config
export const UPDATE_PASSWORD_POLICY = `${API_URL_IDENTITY}/Auth/passwordconfig`
export const GET_USERS_RBT = `${API_URL_ACCOUNTS}/Users?pageIndex=1&pageSize=2000&userName=&roleName=&email=&enabled=&isMobile=false`

/* OMNI APIs */
/* PLACE YOUR APIS HERE */

export const ADD_BLACKLIST = `${API_URL_OMNI_7}/BlackList`;
export const PROCESS_BLACKLIST = `${API_URL_OMNI_7}/BlackList/Process`;
export const DELETE_BLACKLIST = (id = "") => `${API_URL_OMNI_7}/BlackList/${id}`;
export const VALIDATE_BULK_BLACKLIST = `${API_URL_OMNI_7}/BlackList/validate`;
export const SAVE_BULK_BLACKLIST = `${API_URL_OMNI_7}/BlackList`;
export const GET_BLACKLIST = `${API_URL_OMNI_7}/BlackList`;

export const GET_ALL_CHANNELS = `${API_URL_OMNI_1}/channels/channels`;
export const GET_CHANNELS_SUBSCRIBED = `${API_URL_OMNI_1}/channels/account`;
export const GET_BLACKLIST_CHANNELS = `${API_URL_OMNI_1}/channels/BlackList`;

export const GET_API = `${API_URL_APIS}/apis`;
export const GET_COMPANY_API = `${API_URL_APIS}/apis/ApiByCompanyId`;
export const GET_API_WHITELIST = `${API_URL_APIS}/apis/whitelist`;
export const ADD_API = `${API_URL_APIS}/apis`;
export const EDIT_API = `${API_URL_APIS}/apis`;
//export const TEST_API = `${API_URL_OMNI_1}/apis/integration`;
export const TEST_API = ` https://omni-api-dev.montylocal.net/sms/api/SMS`;
export const POST_TEST_API = `https://omni-api-dev.montylocal.net/sms/api/sms/SendBulkSms`;
export const GET_REQUEST_TYPES = `${API_URL_APIS}/apis/requesttypes`;
export const GET_TRAFFIC_TYPES = `${API_URL_APIS}/apis/traffictypes`;
export const GET_SENDERID = `${API_URL_OMNI_1}/senders/account`;

export const GET_CAMPAIGNS = `${API_URL_OMNI_2}/Campaign`;
export const GET_CAMPAIGNS_BY_COMPANY = `${API_URL_OMNI_2}/Campaign/GetCompanyCampaigns`;
export const ADD_CAMPAIGN = `${API_URL_OMNI_2}/Campaign`;
export const TEST_CAMPAIGN = `${API_URL_OMNI_2}/Campaign/SendSMSTest`;
export const TEST_CAMPAIGN_FROM_FILE = `${API_URL_OMNI_2}/Campaign/SendSMSTestFile`;
export const ADD_CAMPAIGN_FROM_FILE = `${API_URL_OMNI_2}/Campaign/CampaignFile`;
export const EDIT_CAMPAIGN = `${API_URL_OMNI_2}/Campaign`;
export const DELETE_CAMPAIGN = `${API_URL_OMNI_2}/Campaign/DeleteCampaign`;
export const PAUSE_CAMPAIGN = `${API_URL_OMNI_2}/Campaign/PauseCampaign`;
export const RESUME_CAMPAIGN = `${API_URL_OMNI_2}/Campaign/ResumeCampaign`;
export const CANCEL_CAMPAIGN = `${API_URL_OMNI_2}/Campaign/CancelCampaign`;
export const GET_CAMPAIGN_BY_ID = (id, accountID) => `${API_URL_OMNI_2}/Campaign/GetByID?campaignId=${id}&AccountID=${accountID}`;
export const GET_CAMPAIGN_LINK_CLICKS_BY_ID = (id) => `${API_URL_OMNI_2}/Campaign/CampaignClick?campaignId=${id}`;
export const CLONE_CAMPAIGN = `${API_URL_OMNI_2}/Campaign/CloneCampaign`;
export const GET_TEMPLATES = `${API_URL_OMNI_2}/Campaign/GetTemplates`;
export const GET_CAMPAIGN_ACCOUNTS = `${API_URL_ACCOUNTS}/accounts/campaign`;
export const SEND_TEST_SMS = `${API_URL_OMNI_1}/SMS/TestSms`;
export const GET_SMS_COST = `${API_URL_OMNI_2}/Campaign/CampaignCost`;
export const GET_SHORT_URL = `${API_URL_OMNI_2}/Campaign/GetShortURL`;
export const GET_BLACKLIST_SHORT_URL = `${API_URL_OMNI_2}/Campaign/GetBlackListShortURL`;
export const GET_CAMPAIGN_VARIABLES = `${API_URL_OMNI_2}/Campaign/CampaignVariables`;
export const GET_CAMPAIGN_PREVIEW = `${API_URL_OMNI_2}/Campaign/GetSMSTransliteration`;
export const VALIDATE_CAMPAIGN = `${API_URL_OMNI_2}/Campaign/ValidateCampaign`;
export const GET_TRIGGER_TYPES = `${API_URL_OMNI_2}/Campaign/AnalyzerType`;
export const GET_TRIGGER_ACTIONS = `${API_URL_OMNI_2}/Campaign/AnalyzerAction`;
export const GET_PAUSED_TIME = `${API_URL_OMNI_2}/Campaign/TimePauseCampaign`;

//Push Notifications
export const GET_APPLICATIONS = `${API_URL_OMNI_NOTIF}/NotificationApp`;
export const ADD_APPLICATION = `${API_URL_OMNI_NOTIF}/NotificationApp`;
export const EDIT_APPLICATION = `${API_URL_OMNI_NOTIF}/NotificationApp`;
export const DELETE_APPLICATION = `${API_URL_OMNI_NOTIF}/NotificationApp`;
export const SEND_NOTIFICATION = `${API_URL_OMNI_NOTIF}/Notification`;
export const GET_NOTIFICATIONS = `${API_URL_OMNI_NOTIF}/Notification`;
export const GET_NOTIFICATION_BY_ID = `${API_URL_OMNI_NOTIF}/Notification`;
export const DELETE_NOTIFICATION = `${API_URL_OMNI_NOTIF}/Notification`;
export const GET_APPLICATION_DASHBOARD = `${API_URL_OMNI_3}/Reports/PushNotificationsUserApplicationsDashboard`;

//Whatsapp
export const GET_TEMP_CATEGORIES = `${API_URL_WHATSAPP_TEMP}/Template/Category`;
export const GET_TEMP_LANGUAGES = `${API_URL_WHATSAPP_TEMP}/Template/Language`;
export const ADD_WHATSAPP_TEMPLATE = `${API_URL_WHATSAPP_TEMP}/Template/AddTemplate`;
export const GET_WHATSAPP_TEMPLATES = `${API_URL_WHATSAPP_TEMP}/Template`;
export const UPLOAD_WHATSAPP_MEDIA = `${API_URL_WHATSAPP_TEMP}/Template/UploadFile`;
export const UPLOAD_WHATSAPP_MEDIA_TEMP = `${API_URL_WHATSAPP_TEMP}/Template/UploadWhatsappFile`;
export const DELETE_WHATSAPP_TEMPLATE = `${API_URL_WHATSAPP_TEMP}/Template`;
export const GET_WHATSAPP_CAMPAIGNS = `${API_URL_WHATSAPP}/MessageTemplate`;
export const SEND_WHATSAPP = `${API_URL_WHATSAPP}/MessageTemplate`;
export const SEND_WHATSAPP_BULK = `${API_URL_WHATSAPP}/MessageTemplate/ListMessageTemplate`;
export const GENERATE_WHATSAPP_EXCEL = `${API_URL_WHATSAPP}/MessageTemplate/GenerateExcelFile`;
export const VALIDATE_WHATSAPP_EXCEL = `${API_URL_WHATSAPP}/MessageTemplate/ValidListMessageTemplate`;

//LiveChat
export const GET_CHAT_CONTACTS = `${API_URL_WHATSAPP}/Message`;
export const GET_CHAT_MESSAGES = `${API_URL_WHATSAPP}/Message/Destination`;
export const SEND_WHATSAPP_MESSAGE = `${API_URL_WHATSAPP}/Message`;

export const GET_ALL_CONTACTS = `${API_URL_PHONEBOOK}/contacts`;
export const GET_CONTACT_DETAILS = `${API_URL_PHONEBOOK}/contacts`;
export const CREATE_CONTACT = `${API_URL_PHONEBOOK}/contacts`;
export const EDIT_CONTACT = `${API_URL_PHONEBOOK}/contacts`;
export const DELETE_CONTACT = id => `${API_URL_PHONEBOOK}/contacts/${id}`;
export const ASSIGN_CONTACT = `${API_URL_PHONEBOOK}/contacts/contactgroup`;
export const VALIDATE_BULK_CONTACTS = `${API_URL_PHONEBOOK}/contacts/validate`;
export const SAVE_BULK_CONTACTS = `${API_URL_PHONEBOOK}/contacts/bulk`;
export const DELETE_MANY_CONTACTS = `${API_URL_PHONEBOOK}/contacts/contactlist`;
export const GET_CONTACTS = `${API_URL_PHONEBOOK}/contacts`;

export const GET_BROADCAST_STATUSES = `${API_URL_OMNI_1}/BroadcastStatuses`;

export const GET_ALL_GROUPS = `${API_URL_PHONEBOOK}/groups`;
export const UPDATE_GROUP = `${API_URL_PHONEBOOK}/groups`;
export const CREATE_GROUP_CONTACT_BULK = `${API_URL_PHONEBOOK}/groups`;
export const DELETE_GROUP = id => `${API_URL_PHONEBOOK}/groups/${id}`;
export const CREATE_GROUP = `${API_URL_PHONEBOOK}/groups`;
export const UPDATE_GROUP_CONTACTS = `${API_URL_PHONEBOOK}/groups/contact`;
export const DELETE_MANY_GROUPS = `${API_URL_PHONEBOOK}/groups/grouplist`;

export const GET_ALL_COUNTRIES = `${API_URL_OMNI_1}/countries`;
export const GET_ACCOUNT_COUNTRIES = `${API_URL_OMNI_1}/Countries/account`;
export const GET_ACCOUNT_COUNTRIES_BY_ID = id => `${API_URL_ACCOUNTS}/accounts/Business/countries/${id}`;

export const GET_TITLES = `${API_URL_OMNI_1}/personaltitles`;
export const GET_GENDERS = `${API_URL_OMNI_1}/genders`;

export const GET_MT_STATISTICS_REPORT = `${API_URL_OMNI_3}/MtReports/Statistics`;
export const GET_MT_STATISTICS_PROFILE_REPORT = `${API_URL_OMNI_3}/MtReports/ProfileStatistics`;
export const GET_MT_DELIVERY_REPORT = `${API_URL_OMNI_3}/MtReports/DlrStatistics`;
export const GET_MT_PROFILE_DELIVERY_REPORT = `${API_URL_OMNI_3}/MtReports/ProfileDlrStatistics`;

export const GET_MO_STATISTICS_REPORT = `${API_URL_OMNI_3}/MoReports/Statistics`;
export const GET_MO_STATISTICS_PROFILE_REPORT = `${API_URL_OMNI_3}/MoReports/ProfileStatistics`;
export const GET_MO_DELIVERY_REPORT = `${API_URL_OMNI_3}/MoReports/DlrStatistics`;
export const GET_MO_PROFILE_DELIVERY_REPORT = `${API_URL_OMNI_3}/MoReports/ProfileDlrStatistics`;

export const GET_ACCOUNT_LOGS_REPORT = `${API_URL_OMNI_3}/AccountReports/AccountLogsReport`;
export const GET_ACCOUNT_TOP_COUNTRIES = `${API_URL_OMNI_3}/AccountReports/AccountTopCountries`;
export const GET_ACCOUNT_TOP_RATE_COUNTRIES = `${API_URL_OMNI_3}/AccountReports/AccountTopRateCountries`;
export const GET_ACCOUNT_LOGS_STATUS = `${API_URL_OMNI_3}/AccountReports/AccountReportStatus`;

export const GET_TRAFFIC_BY_COUNTRY_STATUS = `${API_URL_OMNI_3}/Reports/TrafficByCountryStatusReport`;
export const GET_TRAFFIC_BY_CHANNEL_REPORT = `${API_URL_OMNI_3}/Reports/TrafficByChannelReport`;
export const GET_TRAFFIC_BY_STATUS_REPORT = `${API_URL_OMNI_3}/Reports/TrafficByStatusReport`;
export const GET_TRAFFIC_BY_CHANNEL_SUMMARY_REPORT = `${API_URL_OMNI_3}/Reports/TrafficByChannelSummaryReport`;

//Subscriber per channel
export const GET_SUBSCRIBERS_PER_CHANNEL = `${API_URL_OMNI_3}/Reports/SubscriberPerChannelReport`;

//Bubbled Cuntries 
export const GET_BUBBLED_COUNTRIES = `${API_URL_OMNI_3}/Reports/TrafficByCountryOperatorReport`;


export const GET_SENDERS = `${API_URL_OMNI_1}/senders`;
export const GET_SUBACCOUNT_SENDERS = `${API_URL_OMNI_1}/senders/subaccount`;
export const GET_SENDERS_BY_ACCOUNT = `${API_URL_OMNI_1}/senders/account`;
export const GET_SENDER_TYPES = `${API_URL_OMNI_1}/senders/sendertypes`;
export const DELETE_SENDER = id => `${API_URL_OMNI_1}/senders/${id}`;
export const ADD_SENDER = `${API_URL_OMNI_1}/senders`;
export const UPDATE_SENDER_STATUS = `${API_URL_OMNI_1}/senders/status`;
export const UPDATE_SENDER_ACTIVITY = `${API_URL_OMNI_1}/senders/active`;
export const UPDATE_SENDER = `${API_URL_OMNI_1}/senders`;

export const GET_COUNTRIES_DND = `${API_URL_OMNI_4}/DND`;
export const ADD_COUNTRY_DND = `${API_URL_OMNI_4}/DND`;
export const EDIT_COUNTRY_DND = id => `${API_URL_OMNI_4}/DND/${id}`;
export const DELETE_COUNTRY_DND = id => `${API_URL_OMNI_4}/DND/${id}`;

export const GET_CAMPAIGN = `${API_URL_OMNI_2}/Campaign`;
export const GET_DND_CAMPAIGNS = `${API_URL_OMNI_2}/Campaign/GetDNDCampaign`;

export const GET_TIMEZONES = `${API_URL_OMNI_1}/zones`;
export const GET_TIME_IN_TIMEZONE = `${API_URL_OMNI_1}/Zones/ZoneDateTimeUTC`;

export const GET_ACCOUNTS = `${API_URL_ACCOUNTS}/accounts`;
export const GET_INVOICES_ACCOUNTS = `${API_URL_BILLING}/invoices/accounts`;
export const GET_ACCOUNT_DETAILS = id => `${API_URL_ACCOUNTS}/accounts/${id}`;
export const GET_ROUTES_CLIENTS = `${API_URL_OMNI_1}/routes/accounts`;

export const GET_OPERATORS_LOOKUP = `${API_URL_OMNI_1}/operators/main`;

export const GET_PREFIXES = `${API_URL_OMNI_1}/prefixes`;
export const CREATE_PREFIX = `${API_URL_OMNI_1}/Prefixes`;
export const DELETE_PREFIX = id => `${API_URL_OMNI_1}/prefixes/${id}`;
export const UPDATE_PREFIX = `${API_URL_OMNI_1}/prefixes`;
export const CHANGE_PREFIX_ACTIVITY = `${API_URL_OMNI_1}/prefixes/active`;

export const GET_OPERATORS = `${API_URL_OMNI_1}/operators`;
export const CREATE_OPERATOR = `${API_URL_OMNI_1}/operators`;
export const DELETE_OPERATOR = id => `${API_URL_OMNI_1}/operators/${id}`;
export const UPDATE_OPERATOR = `${API_URL_OMNI_1}/operators`;
export const CHANGE_OPERATOR_ACTIVITY = `${API_URL_OMNI_1}/operators/active`;

export const GET_ROUTES = `${API_URL_OMNI_1}/routes`;
export const CREATE_ROUTE = `${API_URL_OMNI_1}/routes`;
export const EDIT_ROUTE = `${API_URL_OMNI_1}/routes`;
export const DELETE_ROUTE = id => `${API_URL_OMNI_1}/Routes/${id}`;
export const CHANGE_ROUTE_ACTIVITY = `${API_URL_OMNI_1}/routes/active`;

export const GET_CURRENCIES = `${API_URL_OMNI_1}/currencies`;
export const GET_BILLING_CURRENCIES = `${API_URL_BILLING}/payment/currencies`;

export const GET_INVOICES = `${API_URL_BILLING}/invoices`;
export const CREATE_INVOICE = `${API_URL_BILLING}/invoices`;
export const GET_INVOICES_STATUSES = `${API_URL_BILLING}/invoices/statuses`;
export const GET_INVOICES_OUTPUT_TYPES = `${API_URL_BILLING}/invoices/outputs`;
export const GET_INVOICES_CONFIRMATION_STATUSES = `${API_URL_BILLING}/invoices/confirmationstatuses`;
export const TOGGLE_INVOICE_STATUS = `${API_URL_BILLING}/invoices/status`;
export const TOGGLE_INVOICE_CONFIRMATION = `${API_URL_BILLING}/invoices/confirmation`;

export const GET_PAYMENTS = `${API_URL_BILLING}/payment`;
export const GET_PAYMENTS_ADMIN = `${API_URL_BILLING}/PaymentAdmin`;
export const CREATE_PAYMENT = `${API_URL_BILLING}/payment`;
export const CREATE_PAYMENT_ADMIN = `${API_URL_BILLING}/PaymentAdmin`;
export const DELETE_PAYMENT = id => `${API_URL_BILLING}/payment/${id}`;
export const GET_PAYMENT_STATUS = `${API_URL_BILLING}/payment/PaymentStatus`;
export const GET_PAYMENT_TYPES = `${API_URL_BILLING}/payment/PaymentTypes`;
export const UPDATE_PAYMENT_STATUS = `${API_URL_BILLING}/PaymentAdmin`;

export const GET_BILLING_ACCOUNT = `${API_URL_BILLING}/Payment/BillingAccount`;
export const UPDATE_BILLING_ACCOUNT = `${API_URL_BILLING}/Payment/BillingAccount`;
export const ADD_ONLINE_PAYMENT = `${API_URL_BILLING}/Payment/OnlinePayment`;

export const GET_BROADCAST_REPORT = `${API_URL_OMNI_3}/Reports/BroadcastReport`;
export const GET_SUMMARY_REPORT = `${API_URL_OMNI_3}/Reports/SummaryAdminReport`;
export const GET_TRAFFIC_REPORT = `${API_URL_OMNI_3}/Reports/TrafficReportAdmin`;

export const SEND_EMAIL_REPORT = `${API_URL_OMNI_3}/Reports/SendReportByEmail`;

export const GET_VENDORS = `${API_URL_ACCOUNTS}/vendors`;

export const GET_USERS_LOGGING_LOOKUP = `${API_URL_ACCOUNTS}/Users?pageIndex=1&pageSize=10000&userName=&roleName=&email=&enabled=&isMobile=false`;

// accounts
export const GET_ACCOUNT_INFO = API_URL_ACCOUNTS + "/accounts"
export const GET_USER_BALANCE = `${API_URL_BILLING}/balances`;
export const GET_BUSINESS_ACCOUNTS = `${API_URL_ACCOUNTS}/accounts/Business`;
export const ADD_BUSINESS_ACCOUNT = `${API_URL_ACCOUNTS}/accounts/Business`;
export const TOGGLE_BUSINESS_ACCOUNT_ACTIVATION = `${API_URL_ACCOUNTS}/accounts/Business/active`;
export const TOGGLE_CFP = `${API_URL_ACCOUNTS}/accounts/Business/cfp`;
export const TOGGLE_BLOCK = `${API_URL_ACCOUNTS}/accounts/Business/block`;
export const TOGGLE_EMAIL_VERIFICATION = `${API_URL_ACCOUNTS}/accounts/business/email`;
export const GET_ACCOUNT_IP = `${API_URL_ACCOUNTS}/accounts/AllowedAccountIp`;
export const ADD_ACCOUNT_IP = `${API_URL_ACCOUNTS}/accounts/AllowedAccountIp`;
export const DELETE_ACCOUNT_IP = id => `${API_URL_ACCOUNTS}/accounts/AllowedAccountIp/${id}`;
export const GET_ACCOUNT_RATES = `${API_URL_BILLING}/RateException`;
export const GET_RATES = `${API_URL_BILLING}/Rate`;
export const GET_RATE_COST = `${API_URL_BILLING}/Rate/GetCost`;
export const ADD_ACCOUNT_RATE = `${API_URL_BILLING}/RateException`;
export const ADD_RESELLER_RATE = `${API_URL_BILLING}/Rate`;
export const EDIT_ACCOUNT_RATE = `${API_URL_BILLING}/RateException`;
export const ADD_ACCOUNT_RATE_VALIDATE = `${API_URL_BILLING}/RateException/Validate`;
export const ADD_ACCOUNT_RATE_BULK = `${API_URL_BILLING}/RateException`;
export const VALIDATE_RESELLER_RATE = `${API_URL_BILLING}/Rate/Validate`;
export const ADD_RESELLER_RATE_BULK = `${API_URL_BILLING}/Rate`;
export const ADD_RATE = `${API_URL_BILLING}/Rate`;
export const TRANSFER_BALANCE = `${API_URL_BILLING}/balances`;
export const EDIT_ALLOWED_COUNTRIES = `${API_URL_ACCOUNTS}/accounts/Business/countries`;
export const TOGGLE_API_ACTIVATION = `${API_URL_APIS}/apis/active`;
export const GET_PRICING = `${API_URL_BILLING}/RateException/GetRateByAccount`;

export const GET_PARENT_ACCOUNT_CHANNELS = `${API_URL_ACCOUNTS}/accounts/ParentAccountChannel`;
export const GET_ACCOUNT_CHANNELS = `${API_URL_ACCOUNTS}/accounts/AccountChannel`;
export const EDIT_ACCOUNT_CHANNELS = `${API_URL_ACCOUNTS}/accounts/AccountChannel`;

export const GET_RESELLER_ACCOUNTS = `${API_URL_ACCOUNTS}/Resellers`;
export const GET_RESELLER_DETAILS = id => `${API_URL_ACCOUNTS}/Resellers/${id}`;
export const GET_RESELLER_ACCOUNT_LOGO = `${API_URL_ACCOUNTS}/Resellers/AccountsLogo`;
export const ADD_RESELLER_ACCOUNT = `${API_URL_ACCOUNTS}/Resellers`;
export const TOGGLE_RESELLER_ACCOUNT_ACTIVATION = `${API_URL_ACCOUNTS}/Resellers/Active`;
export const TOGGLE_RESELLER_ACCOUNT_ADMIN_ACTIVATION = `${API_URL_ACCOUNTS}/Resellers/Admin`
export const GET_RESELLER_CLIENTS = `${API_URL_ACCOUNTS}/Resellers/Clients`
export const GET_RESELLER_ADMINS = `${API_URL_ACCOUNTS}/Resellers/Admins`

export const GET_CORPORATE_ACCOUNTS = `${API_URL_ACCOUNTS}/accounts/Corporate`;
export const GET_CORPORATE_ACCOUNT_BY_ID = id => `${API_URL_ACCOUNTS}/accounts/Corporate/${id}`;
export const TOGGLE_CORPORATE_ACCOUNT_ACTIVATION = `${API_URL_ACCOUNTS}/accounts/Corporate/active`;
export const EDIT_CORPORATE_ACCOUNT = `${API_URL_ACCOUNTS}/accounts/Corporate`;

export const GET_SUB_ACCOUNTS = `${API_URL_ACCOUNTS}/accounts/Business/sub`;
export const ADD_SUB_ACCOUNT = `${API_URL_ACCOUNTS}/accounts/Business/Sub`;


export const GET_TRAFFIC_PER_COUNTRY = `${API_URL_OMNI_3}/Reports/TrafficByCountryReport`;

export const GET_PAYMENT_NOTIFICATION = `${API_URL_BILLING}/PaymentNotification`;
export const GET_ALERTS = `${API_URL_BILLING}/PaymentNotification/PaymentAlert`;
export const DELETE_PAYMENT_NOTIFICATION = id => `${API_URL_BILLING}/PaymentNotification/${id}`;

export const GET_BALANCE_HISTORY = `${API_URL_BILLING}/Balances/GetBalanceHistory`;

export const GET_ANALYSE_SUB_ACCOUNTS = `${API_URL_ACCOUNTS}/accounts/SubAccountsList`
export const GET_ANALYSE_BUSINESS_AND_SUB_ACCOUNTS = `${API_URL_ACCOUNTS}/accounts/BusinessSubAccountsList`
export const GET_DND_BUSINESS_ACCOUNTS = `${API_URL_ACCOUNTS}/accounts/BusinessAccountsList`

//user dashboard apis
export const GET_DASHBOARD_SMS_CHART = `${API_URL_OMNI_3}/Reports/IncommingOutcommingMessagesReport`;


export const KC_API_PAGES = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/identity/api/v1/Pages/MenuPages" : "https://omni-api-dev.montylocal.net/identity/api/v1/Pages/MenuPages"
export const LOGINCONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    // "Tenant-Key": "d339c160-7b23-4fc9-8284-602f8f9b2683", tenent key doesn't exist on ENTRY login
    "Content-Type": "application/json"
  }
}