const initialState = {
    pages: []
};

const pagesReducer = (state = initialState, { type, pages = [] }) => {
    switch (type) {
        case "REFRESH":
            return { pages };
        default:
            return state;
    }
}

export default pagesReducer;