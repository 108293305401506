import WhiteLabelingActionTypes from './White-Labeling.types';
import Steps from '../../../../Components/Utils/WhiteLabelingSteps';
const INITIAL_STATE = {
    Steps: Steps,
    operatorId: null,
    activeIndex: 0,
    notificationTemplate: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case WhiteLabelingActionTypes.ADD_SMS_TEMPLATE:
            return {
                ...state,
                activeIndex: 1,
                notificationTemplate: [...state.notificationTemplate, ...action.payload]
            }
        case WhiteLabelingActionTypes.ADD_CONTROLS:
            return {
                ...state,
                activeIndex: 2,
                notificationTemplate: [...state.notificationTemplate, ...action.payload]
            }
        case WhiteLabelingActionTypes.ADD_BRANDING:
            return {
                ...state,
                activeIndex: 2,
                notificationTemplate: [...state.notificationTemplate, ...action.payload]
            }
        case WhiteLabelingActionTypes.APPLY_WHITE_LABELING:
            return {...state, activeIndex: 2, notificationTemplate: action.payload}

        case WhiteLabelingActionTypes.GO_TO_BACK:
            return {...state, activeIndex: action.payload}
        default:
            return state;
    }
}
