import React, { Component } from "react"
import { Link } from 'react-router-dom'
// redux related dependencies
import { connect } from 'react-redux'
import {
  mainSignIn, signIn, signOut,
  languageChange, serviceAccessType, branchAccessType,
  multiBranchAccessType, accessTypeNone, tenantKey,
  refreshTokenKey, mainEndPointURL
} from "../Redux/Actions/index"
import { compose } from "redux"

// API
import { KC_API_PAGES, API_ENTRY, API_LOGIN_2, CHANGE_PASSWORD, CREATE_ACCOUNT, GET_SIGNUP_TOKEN, LOGINCONFIG, ALLOW_LOGIN } from "./api"
import { CONFIG, GET_ALL_COUNTRIES, GET_WEBSITE_INFO } from "../Omni/APIs";
import HTTP from "../UTILs/HTTPAbstraction"

// CSS & Styling
import "./Signin.scss"
// other helping modules/dependencies
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBIcon, MDBSpinner
} from 'mdbreact'

//Localization
import { withTranslation } from 'react-i18next'
// localization configuration instance
import i18n from '../i18n-config/i18n'
//Video
import bgVideo from '../Assets/Images/login-bg.svg'
import montyLogo from '../Assets/Images/monty-logo.svg'
import LoginBackground from '../Assets/Images/login-bg.png'
import montymobileLogo from '../Assets/monty-mobile-logo-white.svg'
import loader from "../Assets/loader.gif"
import countryCodeArray from "./countryCode";
import countryCharacter from "./countryCharacter";
import axios from "axios";
import Axios from "axios";
import { axiosErrorHandler } from "../Omni/Containers/util/functions";
import { toast } from "react-toastify"
import packageJson from '../../package.json';
var jwtDecode = require("jwt-decode")

class SignIn extends Component {
  constructor() {
    super();
    this.state = {
      client: new HTTP(),
      errorMessage: "",
      isDisabled: false,
      isDisabledForget: false,
      ShowLoginBox: false,
      showLoader: false,
      showLoaderForget: false,
      temp_username: "",
      country: "",
      // countryCodeList: countryCodeArray.map(({ code, name }) => ({
      //   text: name,
      //   value: code
      // })),
      countryOptions: [],
      displayCreateAccount: false,
      languageSelected: localStorage.getItem("mvp6_language"),
      showPassword: false,

      displaySignUpSuccess: false,

      displayChangePassword: false,
      domainRedirection: "",

      newPassword: "",
      newPasswordConfirmation: "",
      forgetPasswordMsg: "",
      oldPassword: "",
      isTablet: matchMedia("(max-width: 765px)").matches,
      isMobile: matchMedia("(max-width: 426px)").matches,
      allowSignUp: false,
      logoFormat: "",
      uploadLogo: "",
      ip: "",
    }
    this.onChangeField = this.onChangeField.bind(this);
    this.submitSignIn = this.submitSignIn.bind(this);
    this.submitChangePassword = this.submitChangePassword.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleLanguageSelection = this.handleLanguageSelection.bind(this);
    // this.handleChangeLanguage = this.handleChangeLanguage.bind(this)

  }

  componentDidMount() {
    console.log("version", packageJson.version);
    if (localStorage.getItem('mvp6_language') === null) {
      this.handleLanguageSelection("en")
    }
    this.tabletMedia = matchMedia("(max-width: 765px)");
    this.mobileMedia = matchMedia("(max-width: 426px)");
    this.tabletMedia.addEventListener("change", this.setTabletMedia);
    this.mobileMedia.addEventListener("change", this.setMobileMedia);

    Axios.get(`${GET_WEBSITE_INFO}?webUrl=${(window.location.href.replace(/^(?:https?:\/\/)?/i, "")).replace(/\/$/, "")}`, CONFIG).then(({ data }) => {
      this.setState({
        allowSignUp: (typeof data?.result?.allowSignUp === 'undefined') ? true : data?.result?.allowSignUp,
        logoFormat: data?.result?.logoFormat || "",
        uploadLogo: data?.result?.uploadLogo || ""
      }, () => {
        localStorage.setItem("logoFormat", this.state.logoFormat)
        localStorage.setItem("uploadLogo", this.state.uploadLogo)
      })
    })
  }

  componentWillUnmount() {
    this.tabletMedia.removeEventListener("change", this.setTabletMedia);
    this.mobileMedia.removeEventListener("change", this.setMobileMedia);
  }

  componentDidUpdate() {
    //if(this.state.displayCreateAccount) document.querySelectorAll("input").forEach(input => input.autocomplete = "off");
  }

  goBackToLogin = () => this.setState({
    errorMessage: "",
    isDisabled: false,
    isDisabledForget: false,
    ShowLoginBox: false,
    showLoader: false,
    showLoaderForget: false,
    temp_username: "",
    displayChangePassword: false,
    domainRedirection: "",
    newPassword: "",
    newPasswordConfirmation: "",
    forgetPasswordMsg: "",
    oldPassword: "",
    displayCreateAccount: false,
    displaySignUpSuccess: false,
    signupError: "",
  });

  toggleCreateAccount = () => {
    this.getCountries();
    this.setState(({ displayCreateAccount, countryCodeList }) => ({
      displayCreateAccount: !displayCreateAccount,
      displaySignUpSuccess: false,
      errorMessage: "",
      isDisabled: false,
      isDisabledForget: false,
      ShowLoginBox: false,
      showLoader: false,
      showLoaderForget: false,
      temp_username: "",
      domainRedirection: "",
      accountPassword: "",
      confirmPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      forgetPasswordMsg: "",
      oldPassword: "",
      firstName: "",
      lastName: "",
      userName: "",
      companyName: "",
      phoneNumber: "",
      mobileNumber: "",
      email: "",
      countryCode: "",
      signupError: "",
      //countryCodeList: countryCodeList.map(item => ({ ...item, checked: false }))
    }));
  }

  getCountries = () => {
    CONFIG.headers.Authorization = `Bearer ${this.props.token}`;
    Axios.get(GET_ALL_COUNTRIES, CONFIG).then(({ data }) => this.setState({
      countryOptions: data?.result?.map(({ countryId, name, countryCode }) => ({
        text: name,
        value: countryId,
        countryCode: countryCode
      })) || []
    }))
      .catch(err => axiosErrorHandler(err, err?.response?.data?.title));
  }

  getCountryCode = (countryId) => {
    const { countryOptions } = this.state;
    for (var i = 0; i < countryOptions.length; i++) {
      if (countryOptions[i].value == countryId) this.setState({ countryCode: countryOptions[i].countryCode, phoneNumber: countryOptions[i].countryCode, mobileNumber: countryOptions[i].countryCode })
    }
  }

  onChangeField(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  submitSignIn(e) {
    e.preventDefault();
    this.setState({ showLoader: true });
    const [isValid, errorMessage] = this.validateFields();
    if (!isValid) {
      this.setState({
        errorMessage,
        showLoader: false
      })
      return;
    }

    const { client, username, password } = this.state;
    this.setState({
      isDisabled: true
    }, () => {
      // the token response will have a flag, this flag will be one of 3: 
      // 1- the token might inform us that we must go directly to the app, this way we will bypass the 
      // step where we must go to VAS dashboard, instead we will do another following API call.
      // for this we dispatch an action of 'signIn'
      // 2- the token might inform us that we will go to the VAS dashboard
      // for this we dispatch an action of 'mainSignIn'
      // 3- the token might inform us that we will go to Operator dashboard
      // for this we also dispathc an action of mainSignIn
      // for entry token there should be something called "Entry-Key" in the headers
      LOGINCONFIG.headers["Entry-Key"] = "sTNhCIgrkPORZPr+dXYKYHbG/cee41Yj7EL5ctw+xxUrw6GQiaKlZIbGROBKxGEZhWcUt5fCXk+0lEBMbn4QMQ==";
      LOGINCONFIG.headers["Tenant-Key"] = "9ce64459-25d0-4671-a287-8626c4661905"
      client.post(API_LOGIN_2, { password, userName: username }, LOGINCONFIG, "").then(response => {
        if (response.status === 400) {

        }
        if (response?.data?.status == "failure") {
          this.setState({
            isDisabled: false,
            showLoader: false,
            errorMessage: response?.data?.result?.message || this.props.t("Connection Error")
          })
        } else {
          let decoded = jwtDecode(response.data.result.access_token)
          let language = decoded.language ? decoded.language : "en"
          let ExpiredPassword = decoded.ExpiredPassword ? decoded.ExpiredPassword : "false"
          this.props.languageChange(language);
          if (decoded.user_access_level === "Service" && decoded.branches.length > 0 || true) {
            let service = [
              {
                "service_name": "OmniChannel",
                "tenant_key": decoded?.tenant_key,
                "end_point": null,
                "refresh_token_key": "dvImQ2RX4LN91/C+i3dv2rTRtOJ6MD0yxWUV4egFRIiATjDCuLsKk6qeGlBHdMYhA6WrioRJq5zUjMI1NbQHTg=="
              }
            ]
            LOGINCONFIG.headers["Tenant-Key"] = service.tenant_key ? service.tenant_key : "";
            axios.get('https://api64.ipify.org/?format=json').then(({ data }) => {
              this.setState({ ip: data?.ip })
              LOGINCONFIG.headers.Authorization = `bearer ${response.data.result.access_token}`
              Axios.get(`${ALLOW_LOGIN}?webUrl=${(window.location.href.replace(/(^\w+:|^)\/\//, '')).replace(/\/$/, "")}&username=${username}&iP=${this.state.ip}`, LOGINCONFIG).then(resp => {
                //resp?.data?.result == true
                if (true) {
                  const { data } = response
                  if (data.status === "success") {

                    // let innerdecoded = jwtDecode(innerResponse.data.result.access_token)
                    let innerExpiredPassword = decoded.ExpiredPassword ? decoded.ExpiredPassword : false

                    this.props.serviceAccessType();
                    this.props.tenantKey(service.tenant_key);
                    this.props.refreshTokenKey(service.refresh_token_key);
                    this.props.mainEndPointURL(service.end_point);
                    // signIn means we are singing in to the inner service
                    this.props.signIn(data.result);
                      // this.getPages()
                      // const hasDashboard = decoded.pages.sideBar.some(({ path }) => path === "dashboard");
                      Axios.get(KC_API_PAGES, LOGINCONFIG).then(({ data }) => {
                        localStorage.setItem('pages', JSON.stringify(data?.result))
                        this.setState({
                          pages: data?.result
                        }, () => console.log('pages: ', data.result));
                      
                      const hasDashboard = JSON.parse(localStorage.getItem('pages')).sideBar.some(({ path }) => path === "dashboard");
                      if (!innerExpiredPassword) {
                        // this.props.history.push(`/${service.service_name}`)
                        this.props.history.push(hasDashboard ? "/dashboard" : JSON.parse(localStorage.getItem('pages')).sideBar[0].path)
                      } else {
                        this.setState({
                          temp_username: username,
                          displayChangePassword: true,
                          // domainRedirection: service.service_name
                          domainRedirection: hasDashboard ? "dashboard" : JSON.parse(localStorage.getItem('pages')).sideBar[0].path
                        })
                      }
                    }
                    ).catch(err => {
                      axiosErrorHandler(err, err?.response?.data?.title)
                      this.setState({
                        isDisabled: false,
                        errorMessage: this.props.t("Connection Error"),
                        showLoader: false
                      })
                    }).finally(() => {
                      // this.setLoading(false);
                    });
                  } else {
                    this.setState({
                      isDisabled: false, showLoader: false
                    })
                  }
                  // }).catch(exception => {
                  //   if (exception.response && exception.response.status === 401) {
                  //     this.setState({ errorMessage: this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
                  //   } else if (exception.response && (exception.response.status === 400 || exception.response.status === 404)) {
                  //     this.setState({ invalid: this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
                  //   } else {
                  //     this.setState({
                  //       isDisabled: false,
                  //       errorMessage: this.props.t("Connection Error"),
                  //       showLoader: false
                  //     })
                  //   }
                  // })
                } else {
                  this.setState({
                    errorMessage: this.props.t("Invalid Credentials"),
                    showLoader: false
                  })
                }
              }).catch(exception => {
                if (exception.response && exception.response.status === 401) {
                  this.setState({ errorMessage: this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
                } else if (exception.response && (exception.response.status === 400 || exception.response.status === 404)) {
                  this.setState({ invalid: this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
                } else {
                  this.setState({
                    isDisabled: false,
                    errorMessage: this.props.t("Connection Error"),
                    showLoader: false
                  })
                }
              });
            }).catch(err => {
              console.log(err)
              toast.error(this.props.t("Something Went Wrong"))
            })


          } else if (decoded.user_access_level === "Branch") {
            this.props.branchAccessType();
            this.props.mainSignIn(response.data.result);
            this.props.history.push("/OperatorDashboard");
          } else if (decoded.user_access_level === "MultiBranch") {
            this.props.multiBranchAccessType();
            // mainSignIn means we are singing in to the main outer dashboard
            this.props.mainSignIn(response.data.result);
            this.setState({
              isDisabled: false
            })
            this.props.history.push("/headquarter-dashboard");
          } else {

          }
        }
      }).catch(exception => {
        if (exception.response && exception.response.status === 401) {
          this.setState({ errorMessage: exception.response?.data?.result?.message || this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
        } else if (exception.response && (exception.response.status === 400 || exception.response.status === 404)) {
          if (exception?.response?.data?.errors) this.setState({ errorMessage: Object.values(exception?.response?.data?.errors)[0][0], isDisabled: false, showLoader: false })
          else this.setState({ errorMessage: exception?.response?.data?.result?.message || "Invalid Credentials", isDisabled: false, showLoader: false });
        } else {
          this.setState({
            isDisabled: false,
            showLoader: false,
            errorMessage: this.props.t("Connection Error")
          })
        }
      })
    })
  }

  createAccount = e => {
    e.preventDefault();
    CONFIG.headers.Authorization = `Bearer ${this.props.token}`;
    const { "Login-Signature": _, ...rest } = CONFIG.headers;
    const headers = {
      ...rest,
      "Login-Signature": "fnvaN2S43O7wcQk218XOlhOpMEFV7a/OZc617BNbK4DDfyzFOiqS8AhpsNWAETKMvUIdfm2Dk5oXuKCT4OiDIA==",
      "Tenant-Key": "9ce64459-25d0-4671-a287-8626c4661905",
    }
    Axios.post(GET_SIGNUP_TOKEN, null, { headers }).then(({ data }) => {
      this.setState({
        signupToken: data?.result?.loginToken,
      }, () => {
        const { firstName, lastName, companyName, country, countryCode, phoneNumber, mobileNumber, userName, accountPassword, confirmPassword, email, signupToken } = this.state;
        // if (password !== confirmPassword) {
        //   this.setState({
        //     forgetPasswordMsg: "Passwords do not match.",
        //     showLoaderForget: false,
        //     isDisabledForget: false
        //   })
        //   return false
        // }
        this.setState({ showLoader: true });
        //const index = Object.values(countryCharacter).findIndex(value => value === countryCodeArray.find(({ code }) => code === countryCode).name);
        //const countryCharacterCode = Object.keys(countryCharacter)[index];
        const postData = {
          firstName,
          lastName,
          companyName,
          phoneNumber,
          mobileNumber,
          userName,
          password: accountPassword,
          retypedPassword: confirmPassword,
          email,
          countryPhoneCode: countryCode,
          countryId: country,
          weburl: (window.location.href.replace(/^(?:https?:\/\/)?/i, "")).replace(/\/$/, ""),
          enabled: false,
          language: "EN",
          roleName: "Default User",
          title: 10
        };
        // CONFIG.headers.Authorization = `Bearer ${this.state.signupToken}`;
        // const { "Login-Signature": _, ...rest } = CONFIG.headers;
        // const headers = {
        //     ...rest,
        //     "loginToken": signupToken,
        // }
        const { "Login-Signature": _, ...rest } = CONFIG.headers;
        const headers = {
          ...rest,
          "Login-Signature": "fnvaN2S43O7wcQk218XOlhOpMEFV7a/OZc617BNbK4DDfyzFOiqS8AhpsNWAETKMvUIdfm2Dk5oXuKCT4OiDIA==",
          "Tenant-Key": "9ce64459-25d0-4671-a287-8626c4661905",
        }
        axios.put(CREATE_ACCOUNT, postData, { headers }).then(({ data }) => {
          if (data?.status == "success") {
            this.setState({ displayCreateAccount: false, displaySignUpSuccess: true })
          }
          if (data?.title == "Missing Information" || "Not Found" || "Entity Already Exists") {
            this.setState({ signupError: data?.result?.message })
            return false;
          } else {
            this.setState({ signupError: this.props.t("Connection Error") })
          }

        })
          // .catch(exception => {
          //   if (exception.response && exception.response.status === 401) {
          //     this.setState({ forgetPasswordMsg: this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
          //   } else if (exception.response && (exception.response.status === 400 || exception.response.status === 404)) {
          //     this.setState({ invalid: this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
          //   } else {
          //     this.setState({
          //       isDisabled: false,
          //       forgetPasswordMsg: this.props.t("Connection Error"),
          //       showLoader: false
          //     })
          //   }
          // })
          .catch(err => { err?.response?.data?.errors ? this.displayerrors(err?.response?.data?.errors) : this.setState({ signupError: err?.response?.data?.result?.message || this.props.t("Connection Error") }) })
          .finally(() => this.setState({ showLoader: false }));
      })
    })
      .catch(err => axiosErrorHandler(err, err?.response?.data?.title));
  }


  getPages = () => {
   // alert('getpages')
    // this.setLoading(true);
    Axios.get(KC_API_PAGES, LOGINCONFIG).then(({ data }) => {
      localStorage.setItem('pages', JSON.stringify(data?.result))
      this.setState({
        pages: data?.result
      }, () => console.log('pages: ', data.result));
    }
    ).catch(err => axiosErrorHandler(err, err?.response?.data?.title)).finally(() => {
      // this.setLoading(false);
    });
  }


  displayerrors = (object) => {
    if (object) this.setState({ signupError: Object.values(object)[0][0] });
    else this.setState({ signupError: "Connection Error" });
  }

  submitChangePassword(e) {
    e.preventDefault();
    this.setState({ showLoaderForget: true, isDisabledForget: true });
    const { client, oldPassword, newPassword, newPasswordConfirmation } = this.state;

    if (newPassword.replace(/\s/g, "") === "" || newPasswordConfirmation.replace(/\s/g, "") === "") {
      this.setState({
        forgetPasswordMsg: "Empty content not acceptable.",
        showLoaderForget: false,
        isDisabledForget: false
      })
      return false
    }

    if (newPassword !== newPasswordConfirmation) {
      this.setState({
        forgetPasswordMsg: "Passwords do not match.",
        showLoaderForget: false,
        isDisabledForget: false
      })
      return false
    }

    let dataSend = {
      "password": oldPassword,
      "newPassword": newPassword
    }

    client.post(CHANGE_PASSWORD, dataSend, LOGINCONFIG, this.props.token).then(response => {

      this.setState({
        forgetPasswordMsg: response.data.result,
        oldPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
        showLoaderForget: false,
        isDisabledForget: false
      })

      const { temp_username } = this.state;

      LOGINCONFIG.headers["Entry-Key"] = "sTNhCIgrkPORZPr+dXYKYHbG/cee41Yj7EL5ctw+xxUrw6GQiaKlZIbGROBKxGEZhWcUt5fCXk+0lEBMbn4QMQ==";

      client.post(API_ENTRY, { password: newPassword, userName: temp_username }, LOGINCONFIG, "").then(response => {
        if (response.status === 400) return this.goBackToLogin();
        let decoded = jwtDecode(response.data.result.access_token)
        let language = decoded.language ? decoded.language : "en"
        this.props.languageChange(language);

        if (decoded.user_access_level === "Service" && decoded.branches.length > 0) {
          let branch = decoded.branches[0];
          let service = branch.services[0];
          LOGINCONFIG.headers["Tenant-Key"] = service.tenant_key ? service.tenant_key : "";
          client.post(API_LOGIN_2, {}, LOGINCONFIG, response.data.result.access_token).then(innerResponse => {
            const { data } = innerResponse
            if (data.status === "success") {

              let innerdecoded = jwtDecode(innerResponse.data.result.access_token)

              this.props.serviceAccessType();
              this.props.tenantKey(service.tenant_key);
              this.props.refreshTokenKey(service.refresh_token_key);
              this.props.mainEndPointURL(service.end_point);
              // signIn means we are singing in to the inner service
              this.props.signIn(data.result);
              const hasDashboard = innerdecoded.pages.sideBar.some(({ path }) => path === "dashboard");
              this.props.history.push(`/${hasDashboard ? "dashboard" : innerdecoded.pages.sideBar[0].path}`);
            } else {
              this.setState({
                isDisabled: false, showLoader: false
              })
            }
          }).catch(exception => {
            if (exception.response && exception.response.status === 401) {
              this.setState({ forgetPasswordMsg: this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
            } else if (exception.response && (exception.response.status === 400 || exception.response.status === 404)) {
              this.setState({ invalid: this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
              if (response.status === 400) return this.goBackToLogin();
            } else {
              this.setState({
                isDisabled: false,
                forgetPasswordMsg: this.props.t("Connection Error"),
                showLoader: false
              })
            }
          })
        } else if (decoded.user_access_level === "Branch") {
          this.props.branchAccessType();
          this.props.mainSignIn(response.data.result);
          this.props.history.push("/OperatorDashboard");
        } else if (decoded.user_access_level === "MultiBranch") {
          this.props.multiBranchAccessType();
          // mainSignIn means we are singing in to the main outer dashboard
          this.props.mainSignIn(response.data.result);
          this.setState({
            isDisabled: false
          })
          this.props.history.push("/headquarter-dashboard");
        } else {

        }
      }).catch(exception => {
        if (exception.response && exception.response.status === 401) {
          this.setState({ forgetPasswordMsg: exception.response?.data?.result?.message || this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
        } else if (exception.response && (exception.response.status === 400 || exception.response.status === 404)) {
          this.setState({ forgetPasswordMsg: exception.response?.data?.result?.message || this.props.t("Invalid Credentials"), isDisabled: false, showLoader: false });
        } else {
          this.setState({
            isDisabled: false,
            showLoader: false,
            forgetPasswordMsg: this.props.t("Connection Error")
          })
        }
      })

    }).catch(exception => {
      if (exception.response) {
        this.setState({
          forgetPasswordMsg: exception.response.data.result.message,
          showLoaderForget: false,
          isDisabledForget: false
        })
      }
    })

  }

  validateFields() {
    const { username, password } = this.state;

    let errorMessage = "";
    let isValid = true;
    // if (!username || username.length < 8) {
    //   errorMessage = this.props.t("Username must be 8 characters at least");
    //   isValid = false;
    //   return [isValid, errorMessage]
    // }

    // if (!password || password.length < 8) {
    //   errorMessage = this.props.t("Password must be 8 characters at least")
    //   isValid = false;
    //   return [isValid, errorMessage]
    // }

    return [isValid, errorMessage]
  }

  showLoginFields() {
    this.setState({
      ShowLoginBox: true
    })
  }

  handleLanguageSelection(lang) {

    localStorage.setItem('mvp6_language', lang);
    i18n.changeLanguage(lang);
    this.props.languageChange(lang);

    this.setState((prevState) => ({
      languageSelected: !prevState.languageSelected
    }))
  }

  onPhoneNumberChange = ({ target: { name, value } }) => {
    if (value.length > 0 && /^\d+$/.test(value.substr(String(this.state.countryCode).length)))
      this.setState({ [name]: value });
    else if (value.length === String(this.state.countryCode).length)
      this.setState({ [name]: String(this.state.countryCode) });
  }

  setTabletMedia = ({ matches }) => this.setState({ isTablet: matches });
  setMobileMedia = ({ matches }) => this.setState({ isMobile: matches });

  toggleShowPassword = () => this.setState(({ showPassword }) => ({ showPassword: !showPassword }));

  render() {
    const { loading, showPassword, isMobile, isTablet, countryOptions, allowSignUp, uploadLogo, logoFormat } = this.state;
    const { t, history } = this.props;
    //let isLoggedIn = token !== "" || loginToken !== [] && loginToken !== "" && loginToken !== undefined || false;
    return (
      <div className="login">
        <MDBRow className="mx-0 login-container">
          {!isMobile && !isTablet && <MDBCol md="6" lg="7" className="left px-0">
            {/* <img
              src={LoginBackground}
              alt={t("Background")} style={{
                width: "100%",
                objectFit: "stretch",
                left: "0",
                position: "absolute"
              }}
            /> */}

          </MDBCol>}

          {!this.state.displayChangePassword && !this.state.displayCreateAccount && !this.state.displaySignUpSuccess && <MDBCol md="6" lg="5" middle className="right">
            <div className="center-right">

              <form onSubmit={this.submitSignIn} style={isTablet ? {} : { width: "500px" }}>
                <MDBCol className="text-right">
                  {uploadLogo == "" && !localStorage.getItem("logoFormat") ? <img
                    src={montymobileLogo}
                    alt={t("logo")}
                    style={{
                      width: "160px",
                      height: "50px",
                      right: "0",
                      marginBottom: "42px",
                    }}
                  /> : <div className="text-right resizedImage"><img
                    src={localStorage.getItem("logoFormat") + "," + localStorage.getItem("uploadLogo") || logoFormat + "," + uploadLogo}
                    alt={t("logo")}
                    style={{
                      width: "auto",
                      height: "auto",
                      right: "0",
                      marginBottom: "42px",
                    }}
                    className="logoImage"
                  />
                  </div>}
                </MDBCol>
                <h1
                  className="welcome-text"
                  style={{ marginBottom: "30px" }}
                >
                  {t("Welcome to")}
                  <br />
                  {t("Monty Mobile's Portal")}
                </h1>
                <p className="input-label">Username</p>
                <MDBInput
                  name="username"
                  type="text"
                  placeholder={t("Username")}
                  outline
                  maxLength="64"
                  onChange={this.onChangeField}
                  containerClass="custom-input-container"
                  group
                  error="wrong"
                  success="right"
                  style={{ height: "50px", marginTop: "-15px" }}
                >
                </MDBInput>
                <p className="input-label">Password</p>
                <MDBInput
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Password")}
                  outline
                  maxLength="50"
                  onChange={this.onChangeField}
                  containerClass="custom-input-container"
                  group
                  validate
                  style={{ height: "50px", marginTop: "-15px" }}
                >
                  <MDBIcon
                    icon={showPassword ? "eye" : "eye-slash"}
                    onClick={this.toggleShowPassword}
                    className="eye-icon"
                  />
                </MDBInput>
                <Link to="/forgot-password" className="forgot-password"><p className="text-right" style={{ marginTop: "-30px", marginBottom: "30px", textDecoration: "underline" }}>{t("Forgot my password")}</p></Link>
                <MDBBtn
                  type="submit"
                  disabled={loading}
                  className="w-100 mx-0 p-2 login-btn mb-3"
                  onClick={this.submitSignIn}
                >
                  <span className={this.state.showLoader ? 'hide' : 'show'} >{t("SIGN IN")}</span>
                  <img className={this.state.showLoader ? 'show' : 'hide'} src={loader} alt="loader" width="30px" />
                </MDBBtn>
                <div className="py-1">
                  <p className="color-ff3547 text-center mb-0 px-3">
                    {this.state.errorMessage}
                  </p>

                  {/* <p className="text-center">{t("OR")}</p> */}
                </div>
                {/* <MDBBtn
                disabled={loading}
                color="reset"
                className="text-pink w-100 mx-0 p-2 login-btn mb-4"
              >
                { t("Log In with Google Account") }
              </MDBBtn> */}
                {/* <MDBBtn
                disabled={loading}
                color="reset"
                className="text-pink w-100 mx-0 p-2 login-btn mt-2 mb-4"
              >
                { t("Log In with GitHub") }
              </MDBBtn> */}
                <p className="text-light text-left">{t("By clicking the button, you agree to our Terms & Conditions and Privacy Policy")}</p>
                {allowSignUp && <p
                  className="text-light text-left"
                  style={{ fontSize: "17px" }}
                >
                  {t("Don't have an account?")} &nbsp;
                  <Link><b className="font-weight-bold text-danger" onClick={this.toggleCreateAccount}>{t("Sign up!")}</b></Link>
                </p>}
              </form>
            </div>
          </MDBCol>}
          {this.state.displayChangePassword && (
            <MDBCol md="6" lg="5" middle className="right">
              <div className="center-right">
                <form onSubmit={this.submitChangePassword} style={isTablet ? {} : { width: "500px" }}>
                  <h1 className="text-left welcome-text">{t("Change Password")}</h1>
                  <div className={this.props.lang === "ar" ? "text-arabic" : ""}>
                    <MDBInput
                      label={t("Old Password")}
                      name="Old password"
                      group
                      type={showPassword ? "text" : "password"}
                      outline
                      validate
                      containerClass="custom-input-container"
                      style={{ paddingLeft: "15px", paddingTop: "12px", paddingBottom: "12px" }}
                      onChange={e => this.setState({ oldPassword: e.target.value })}
                      value={this.state.oldPassword}
                    >
                      <MDBIcon
                        icon={showPassword ? "eye" : "eye-slash"}
                        onClick={this.toggleShowPassword}
                        className="eye-icon"
                      />
                    </MDBInput>

                    <MDBInput
                      label={t("New Password")}
                      name="New password"
                      group
                      type={showPassword ? "text" : "password"}
                      validate
                      outline
                      maxLength="50"
                      containerClass="custom-input-container"
                      style={{ paddingLeft: "15px", paddingTop: "12px", paddingBottom: "12px" }}
                      onChange={e => this.setState({ newPassword: e.target.value })}
                      value={this.state.newPassword}
                    >
                      <MDBIcon
                        icon={showPassword ? "eye" : "eye-slash"}
                        onClick={this.toggleShowPassword}
                        className="eye-icon"
                      />
                    </MDBInput>

                    <MDBInput
                      label={t("Confirm password")}
                      name="Confirm password"
                      group
                      type={showPassword ? "text" : "password"}
                      validate
                      outline
                      maxLength="50"
                      containerClass="custom-input-container"
                      style={{ paddingLeft: "15px", paddingTop: "12px", paddingBottom: "12px" }}
                      onChange={e => this.setState({ newPasswordConfirmation: e.target.value })}
                      value={this.state.newPasswordConfirmation}
                    >
                      <MDBIcon
                        icon={showPassword ? "eye" : "eye-slash"}
                        onClick={this.toggleShowPassword}
                        className="eye-icon"
                      />
                    </MDBInput>

                  </div>

                  <MDBRow center>
                    <p className="color-ff3547">
                      {this.state.forgetPasswordMsg}
                    </p>
                  </MDBRow>

                  <div className="text-center mb-3">
                    <MDBBtn
                      disabled={loading}
                      className="w-100 mx-0 p-3 login-btn"
                      onClick={this.submitChangePassword}
                    >
                      {loading ? <MDBSpinner small className="mdbspinner-white" /> : <span>{t("Submit")}</span>}
                    </MDBBtn>
                  </div>
                  <Link><p
                    onClick={this.goBackToLogin}
                    className="text-center text-light mt-5 pt-5"
                  >
                    {t("Go Back")}
                  </p>
                  </Link>
                </form>


              </div>
            </MDBCol>
          )}
          {this.state.displayCreateAccount && !this.state.displaySignUpSucces && <MDBCol md="6" lg="5" middle className="right">
            <div className="center-right">
              <form onSubmit={this.createAccount} style={isTablet ? {} : { width: "550px" }} >
                <h1
                  className="signup-text"
                  style={{ marginBottom: "20px" }}
                >
                  {t("Start by creating your MontyMobile Portal account")}
                </h1>

                <MDBRow>
                  <MDBCol md="6"  >
                    <MDBInput
                      label={t("First Name")}
                      name="firstName"
                      outline
                      value={this.state.firstName}
                      containerClass="custom-input-container"
                      maxLength={15}
                      error="wrong"
                      success="right"
                      onChange={this.onChangeField}
                      style={{ height: "45px", marginTop: "-15px" }}
                    />
                  </MDBCol>
                  <MDBCol md="6"  >
                    <MDBInput
                      label={t("Last Name")}
                      name="lastName"
                      outline
                      value={this.state.lastName}
                      containerClass="custom-input-container"
                      maxLength={15}
                      error="wrong"
                      success="right"
                      onChange={this.onChangeField}
                      style={{ height: "45px", marginTop: "-15px" }}
                    />
                  </MDBCol>
                  <MDBCol md="6"  >
                    <MDBInput
                      label={t("User Name")}
                      name="userName"
                      outline
                      value={this.state.userName}
                      containerClass="custom-input-container"
                      group
                      maxLength={16}
                      error="wrong"
                      success="right"
                      onChange={this.onChangeField}
                      style={{ height: "45px", marginTop: "-15px" }}
                    />
                  </MDBCol>
                  <MDBCol md="6"  >
                    <MDBInput
                      label={t("Email")}
                      name="email"
                      outline
                      value={this.state.email}
                      containerClass="custom-input-container"
                      type="email"
                      group
                      maxLength="50"
                      error="wrong"
                      success="right"
                      onChange={this.onChangeField}
                      style={{ height: "45px", marginTop: "-15px" }}
                    />
                  </MDBCol>
                  <MDBCol md="6"  >
                    <MDBInput
                      label={t("Password")}
                      type={showPassword ? "text" : "password"}
                      containerClass="custom-input-container"
                      name="accountPassword"
                      outline
                      value={this.state.accountPassword}
                      autoComplete="new-password"
                      group
                      maxLength={16}
                      error="wrong"
                      success="right"
                      onChange={this.onChangeField}
                      style={{ height: "45px", marginTop: "-15px" }}
                    >
                      <MDBIcon
                        icon={showPassword ? "eye" : "eye-slash"}
                        onClick={this.toggleShowPassword}
                        className="eye-icon-small"
                        style={{ marginTop: "-40px", paddingRight: "15px" }}
                      />
                    </MDBInput>
                  </MDBCol>
                  <MDBCol md="6"  >
                    <MDBInput
                      label={t("Confirm Password")}
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      outline
                      value={this.state.confirmPassword}
                      containerClass="custom-input-container"
                      group
                      maxLength={16}
                      error="wrong"
                      success="right"
                      onChange={this.onChangeField}
                      style={{ height: "45px", marginTop: "-15px" }}
                    >
                      <MDBIcon
                        icon={showPassword ? "eye" : "eye-slash"}
                        onClick={this.toggleShowPassword}
                        className="eye-icon-small"
                        style={{ marginTop: "-40px", paddingRight: "15px" }}
                      />
                    </MDBInput>
                  </MDBCol>
                  <MDBCol md="6"  >
                    <MDBInput
                      label={t("Company Name")}
                      name="companyName"
                      outline
                      maxLength={30}
                      value={this.state.companyName}
                      containerClass="custom-input-container"
                      group
                      error="wrong"
                      success="right"
                      onChange={this.onChangeField}
                      style={{ height: "45px", marginTop: "-15px" }}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="custom-selector" >
                    {/* <MDBSelect
                            label={t("Select Country")}
                            search
                            outline
                            selected={t("Select Country")}
                            options={this.state.countryCodeList}
                            getValue={value => this.setState({
                              countryCode: value[0],
                              phoneNumber: value[0],
                              mobileNumber: value[0]
                            })}
                            className="mt-4"
                          /> */}
                    <MDBSelect
                      selected={t("Country")}
                      label={t("Country")}
                      outline
                      options={countryOptions}
                      search
                      id="country-dropdown"
                      getValue={([country = ""]) => this.setState({ country }, () => { this.getCountryCode(this.state.country) })}
                      style={{ height: "50px", marginTop: "10px" }}
                    />
                  </MDBCol>
                  {this.state.countryCode && <>
                    <MDBCol md="6"  >
                      <MDBInput
                        label={t("Phone Number")}
                        name="phoneNumber"
                        maxLength={20}
                        value={this.state.phoneNumber}
                        containerClass="custom-input-container"
                        outline
                        inputMode="numeric"
                        group
                        error="wrong"
                        success="right"
                        onChange={this.onPhoneNumberChange}
                        style={{ height: "45px", marginTop: "-15px" }}
                      />
                    </MDBCol>
                    <MDBCol md="6"  >
                      <MDBInput
                        label={t("Mobile Number")}
                        name="mobileNumber"
                        outline
                        maxLength={20}
                        value={this.state.mobileNumber}
                        containerClass="custom-input-container"
                        inputMode="numeric"
                        group
                        error="wrong"
                        success="right"
                        onChange={this.onPhoneNumberChange}
                        style={{ height: "45px", marginTop: "-15px" }}
                      />
                    </MDBCol>
                  </>}
                </MDBRow>
                <MDBRow className="text-center mb-3" center>
                  {this.state.signupError && <p className="text-danger">{this.state.signupError}</p>}
                  <MDBBtn
                    type="submit"
                    className="w-100 mx-2 p-2 login-btn mb-4"
                    //disabled={this.state.isDisabled || !this.state.firstName || !this.state.lastName || !this.state.companyName || !this.state.countryCode || !this.state.phoneNumber || !this.state.mobileNumber || !this.state.email || !this.state.userName || !this.state.password}
                    disabled={this.state.showLoader}
                  //style={{ cursor: "not-allowed" }}
                  >
                    <span className={this.state.showLoader ? 'hide' : 'show'} >{t("Sign Up")}</span>
                    <img className={this.state.showLoader ? 'show' : 'hide'} src={loader} alt="loader" width="30px" />
                  </MDBBtn>
                </MDBRow>

                <div className="mt-3">
                  <p className="text-light text-left">{t("By clicking the button, you agree to our Terms & Conditions and Privacy Policy")}</p>
                  <p
                    className="text-light text-left"
                    style={{ fontSize: "17px" }}
                  >
                    {t("Already have an account?")} &nbsp;
                    <Link to="/" onClick={this.toggleCreateAccount}><b className="font-weight-bold text-danger">{t("Sign in!")}</b></Link>
                  </p>
                </div>
                <MDBRow center>
                  <p className="color-ff3547 text-center mb-0 px-3">
                    {this.state.errorMessage}
                  </p>
                </MDBRow>
              </form>
            </div>
          </MDBCol>}
          {this.state.displaySignUpSuccess && <MDBCol md="6" lg="5" middle className="right">
            <div className="center-right">
              <div style={isTablet ? {} : { width: "550px" }} >
                <h1
                  className="text-center"
                  style={{ marginBottom: "20px" }}
                >
                  <MDBIcon icon="check-circle" size="4x" style={{ paddingBottom: "25px", color: "#fff" }} />
                  <br />
                  <b className="welcome-text">{t("Sign Up Successful!")}</b>
                </h1>
                <h1
                  className="text-center text-white"
                  style={{ marginBottom: "20px" }}
                >
                  {t("Please check your email for Verification")}
                </h1>
                <MDBRow className="text-center mb-3" center>
                  {this.state.signupError && <p className="text-danger">{this.state.signupError}</p>}
                  <MDBBtn
                    className="w-100 mx-2 p-3 login-btn mb-4 mx-5"
                    //onClick={() => this.goBackToLogin()}
                    onClick={() => history?.push({ pathname: `/otp`, state: { username: this.state.userName } })}
                  >
                    {/* <span >{t("Go Back to Sign In")}</span> */}
                    {/* <Link to={{pathname: `/otp`, state: { username: this.state.userName }}} style={{color: "white"}}> */}
                    <span >{t("Go to OTP Page")}</span>
                    {/* </Link> */}
                  </MDBBtn>
                </MDBRow>
              </div>
            </div>
          </MDBCol>}
        </MDBRow>

        <MDBRow className="footer-mainpage mx-0">
          <MDBContainer className="position-absolute top-40percent left-50percent translate-50percent">
            <MDBRow center>
              <MDBCol md="12">
                <ul className={this.props.lang === "ar" ? "text-arabic footer-main-menu px-0" : "footer-main-menu px-0"}>
                  {/* <li><a href={"#"}>Privacy Policy</a></li>
                  <li><a href={"#"}>Terms &amp; Conditions</a></li> */}
                  {/* <img src={montymobileLogo} alt="Logo" style={{marginLeft:"-500px", width: "200px"}}/> */}
                  {/* <li className="px-0"><p>{t("Copyright")} © {new Date().getFullYear()} Monty Mobile. {t("All rights reserved")}.</p></li> */}
                </ul>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  token: state.authentication ? state.authentication.token : "",
  errorMessage: "",
  loginToken: state.authentication ? state.authentication.loginToken : [],
  lang: state.language ? state.language.lang : "en"
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  mainSignIn: (token) => dispatch(mainSignIn(token)),
  signIn: (token) => dispatch(signIn(token)),
  signOut: () => dispatch(signOut()),
  languageChange: (lang) => dispatch(languageChange(lang)),
  serviceAccessType: () => dispatch(serviceAccessType()),
  branchAccessType: () => dispatch(branchAccessType()),
  multiBranchAccessType: () => dispatch(multiBranchAccessType()),
  accessTypeNone: () => dispatch(accessTypeNone()),
  tenantKey: (key) => dispatch(tenantKey(key)),
  refreshTokenKey: (key) => dispatch(refreshTokenKey(key)),
  mainEndPointURL: (url) => dispatch(mainEndPointURL(url))
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withTranslation("translations")(SignIn));