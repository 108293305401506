const steps = [
    {
        "id": "1",
        "label" : "SMS Template",
        "completedClass": ""
    },
    {
        "id": "2",
        "label" : "Controls",
        "completedClass": ""
    },
    {
        "id": "3",
        "label" : "Branding",
        "completedClass": ""
    }
];

export default steps;