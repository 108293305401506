import MasterInfoActionTypes from './Master-Info.types';
const INITIAL_STATE = {
    masterInfo: []
}
export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case MasterInfoActionTypes.FETCH_MASTER_LIST:
            return {...state, masterInfo:action.payload}
        default:
            return state;
    }
}