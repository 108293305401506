export const API_URL_IDENTITY = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://identity-qa.montylocal.net/api/v1" : "https://identity-dev.montylocal.net/api/v1";
export const API_URL_IDENTITY_V2 = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://identity-qa.montylocal.net/api/v2" : "https://identity-dev.montylocal.net/api/v2";
export const API_URL_IDENTITY_V3 = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://identity-qa.montylocal.net/api/v3" : "https://identity-dev.montylocal.net/api/v3";

export const API_LOGIN = API_URL_IDENTITY + "/Auth/AccessToken";

export const API_ENTRY = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/login/Entry" : "https://omni-api-dev.montylocal.net/registration/api/login/Entry";
export const API_LOGIN_2 = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/login/AccessToken" : "https://omni-api-dev.montylocal.net/registration/api/login/AccessToken";
export const CREATE_ACCOUNT = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/login" : "https://omni-api-dev.montylocal.net/registration/api/login";
export const VERIFY_OTP = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/login/Activation" : "https://omni-api-dev.montylocal.net/registration/api/login/Activation";
export const ALLOW_LOGIN = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/Resellers/ValidLogin" : "https://omni-api-dev.montylocal.net/registration/api/Resellers/ValidLogin";

export const REFRESH_TOKEN = API_URL_IDENTITY_V2 + "/Auth/Refresh"
export const RESET_PASSWORD = API_URL_IDENTITY + "/Account/ResetPassword";
export const FORGET_PASSWORD = API_URL_IDENTITY + "/Account/ForgetPassword";
export const GET_SIGNUP_TOKEN = API_URL_IDENTITY + "/Auth/login";
export const CHANGE_PASSWORD = API_URL_IDENTITY_V2 + "/Account/ChangePassword";

export const LOGINCONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    // "Tenant-Key": "d339c160-7b23-4fc9-8284-602f8f9b2683", tenent key doesn't exist on ENTRY login
    "Content-Type": "application/json"
  }
}

export const CONFIGNOAHTH = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Content-Type": "application/json"
  }
};

export const RESETLOGINCONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Tenant-Key": "9ce64459-25d0-4671-a287-8626c4661905", //tenent key doesn't exist on ENTRY login
    "Content-Type": "application/json"
  }
}


//KEYCLOAK identity 
export const KC_API_LOGIN="https://omni-api-dev.montylocal.net/identity/api/v1/Auth/AccessToken"
export const KC_API_PAGES= window.location.origin.includes("https://omni-qa.montylocal.net") ?  "https://omni-api-qa.montylocal.net/identity/api/v1/Pages/MenuPages" : "https://omni-api-dev.montylocal.net/identity/api/v1/Pages/MenuPages"

