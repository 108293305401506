const OperatorActionTypes = {
    FETCH_OPERATOR : "FETCH_OPERATOR",
    FETCH_OPERATOR_BY_ID: "FETCH_OPERATOR_BY_ID",
    ADD_OPERATOR_INFO : "ADD_OPERATOR_INFO",
    ADD_OPERATOR_CLICK : "ADD_OPERATOR_CLICK",
    ADD_CONFIG: "ADD_CONFIG",
    ADD_BILLING_INFO: "ADD_BILLING_INFO",
    CREATE_OPERATOR: "CREATE_OPERATOR",
    UPDATE_OPERATOR: "UPDATE_OPERATOR",
    GO_TO_BACK: "GO_TO_BACK",
    SHOW_WHITELABELING: "SHOW_WHITELABELING",
    SHOW_HISTORY: "SHOW_HISTORY",
    SHOW_EDIT_OPERATOR: "SHOW_EDIT_OPERATOR",
    CANCLE_CLICK: "CANCLE_CLICK",
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_LOADER: "SHOW_LOADER"
}

export default OperatorActionTypes;