import { toast } from "react-toastify";

/**
    * Downloads CSV
    * @param {Array} columns Table Columns
    * @param {Array} data Table Data
    * @param {string} title Name of the Excel File
    * @param {Function} t Translation Function (i18n)
*/
export const downloadCSV = (columns = [], data = [], title = "Excel", t = value => value) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(columns, data);
    if (!csv) return;
    const filename = `${t(title)}.csv`;
    if (!csv.match(/^data:text\/csv/i)) 
        csv = `data:text/csvcharset=utf-8,${csv}`;
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
}

/**
    * Converts array of objects to CSV
    * @param {Array} columns Table Columns
    * @param {Array} data Table Data
*/
export const convertArrayOfObjectsToCSV = (columns = [], data = []) => {
    let result = "", keys = [];
    columns.forEach(element => {
        if (element.hasOwnProperty("export")) {
            if (element.export) 
                keys.push(element.title);
        } else if (element.hasOwnProperty("hidden")) {
            if (!element.hidden) 
                keys.push(element.title);
        } else 
            keys.push(element.title);
    });
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    columns.forEach((element, index) => {
        if (element.hasOwnProperty("export")) {
            if (element.export) 
                keys[index] = element.field || "";
        } else if (element.hasOwnProperty("hidden")) {
            if (!element.hidden) 
                keys[index] = element.field || "";
        } else 
            keys[index] = element.field || "";
    });
    data.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
                const edited = typeof item[key] == "string" ? item[key]?.replace(/,/g, ";")?.replace(/#/g, " ") : item[key];
                result += edited;
            ctr ++;
        });
        result += lineDelimiter;
    });
    return result;
}

/**
    * A function dedicated to handle all kinds of Axios network errors
    * @param {object} err Axios Catch Error
    * @param {string} exactError Exact Response Error
    * @param {string} message Custom Message Error
    * @param {Function} callback A callback for catching an error
    * @param {Function} t Translation Function (i18n)
    * @param {Array|object} errors Multiple Errors
*/
export const axiosErrorHandler = (err = {}, exactError = null, message = "Something Went Wrong", t = value => value, callback = null, errors = null) => {
    console.error(err);
    if (!err.response) toast.error(t("Connection Error"));
    else if (errors) {
        if (Array.isArray(errors))
            errors.forEach(err => toast.error(err));
        else if (typeof errors === "object")
            Object.values(errors).map(error => {
                if (Array.isArray(error))
                    error.forEach(err => toast.error(err))
                else toast.error(error);
            });
        else if (typeof errors === "string")
            toast.error(errors);
        else toast.error(exactError || err.response?.data?.result?.message || err.response?.data?.result?.message || message);
    } else toast.error(exactError || err.response?.data?.result?.message || err.response?.data?.result?.message || message);
    typeof callback === "function" && callback();
}

/**
    * Convert bytes to its correct size format
    * @param {number} bytes File Size Bytes
    * @param {number} decimals Number of Decimals
*/
export const bytesToSize = (bytes = 0, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const value = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, value)).toFixed(dm))} ${sizes[value]}`;
}

/**
    * Convert Date to YYYY/MM/DD
    * @param {string|number|Date} date Date Format (default: "")
    * @param {string} separator Date Separator (default: "/")
*/
export const get_DD_MM_YYYY = (date = "", separator = "/") => {
    if (!date) 
        return "";
    const modifiedDate = new Date(date);
    let dd = String(modifiedDate.getDate()).padStart(2, "0");
    let mm = String(modifiedDate.getMonth() + 1).padStart(2, "0"); // January is 0
    let yyyy = modifiedDate.getFullYear();
    return `${dd}${separator}${mm}${separator}${yyyy}`;
}

/**
    * Convert Date to YYYY/MM/DD
    * @param {string|number|Date} date Date Format (default: "")
    * @param {string} separator Date Separator (default: "/")
*/
export const get_YYYY_MM_DD = (date = "", separator = "/") => {
    if (!date) 
        return "";
    const modifiedDate = new Date(date);
    let dd = String(modifiedDate.getDate()).padStart(2, "0");
    let mm = String(modifiedDate.getMonth() + 1).padStart(2, "0"); // January is 0
    let yyyy = modifiedDate.getFullYear();
    return `${yyyy}${separator}${mm}${separator}${dd}`;
}

/**
    * Convert Date to YYYY/MM/DD HH:mm:ss
    * @param {string|number|Date} date Date Format (default: "")
    * @param {string} separator Date Separator (default: "/")
    * @param {string} timeSeparator Time Separator (default: ":")
*/
export const get_YYYY_MM_DD_HH_MM_SS = (date = "", separator = "/", timeSeparator = ":") => {
    if (!date) 
        return "";
    const modifiedDate = new Date(date);
    let dd = String(modifiedDate.getDate()).padStart(2, "0");
    let mm = String(modifiedDate.getMonth() + 1).padStart(2, "0"); // January is 0
    let yyyy = modifiedDate.getFullYear();
    let m = modifiedDate.getMinutes();
    let hh = modifiedDate.getHours();
    let ss = modifiedDate.getSeconds();
    if (hh < 10)
        hh = `0${hh}`;
    if (m < 10)
        m = `0${m}`;
    if (ss < 10)
        ss = `0${ss}`;
    return `${yyyy}${separator}${mm}${separator}${dd} ${hh}${timeSeparator}${m}${timeSeparator}${ss}`;
}

/**
    * Convert Date to YYYY/DD/MM HH:mm:ss
    * @param {string|number|Date} date Date Format (default: "")
    * @param {string} separator Date Separator (default: "/")
    * @param {string} timeSeparator Time Separator (default: ":")
*/
export const get_YYYY_DD_MM_HH_MM_SS = (date = "", separator = "/", timeSeparator = ":") => {
    if (!date) 
        return "";
    const modifiedDate = new Date(date);
    let dd = String(modifiedDate.getDate()).padStart(2, "0");
    let mm = String(modifiedDate.getMonth() + 1).padStart(2, "0"); // January is 0
    let yyyy = modifiedDate.getFullYear();
    let m = modifiedDate.getMinutes();
    let hh = modifiedDate.getHours();
    let ss = modifiedDate.getSeconds();
    if (hh < 10)
        hh = `0${hh}`;
    if (m < 10)
        m = `0${m}`;
    if (ss < 10)
        ss = `0${ss}`;
    return `${yyyy}${separator}${dd}${separator}${mm} ${hh}${timeSeparator}${m}${timeSeparator}${ss}`;
}

/**
    * Convert Date to MM/DD/YYYY HH:mm:ss
    * @param {string|number|Date} date Date Format (default: "")
    * @param {string} separator Date Separator (default: "/")
    * @param {string} timeSeparator Time Separator (default: ":")
*/
export const get_MM_DD_YYYY_HH_MM_SS = (date = "", separator = "/", timeSeparator = ":") => {
    if (!date) 
        return "";
    const modifiedDate = new Date(date);
    let dd = String(modifiedDate.getDate()).padStart(2, "0");
    let mm = String(modifiedDate.getMonth() + 1).padStart(2, "0"); // January is 0
    let yyyy = modifiedDate.getFullYear();
    let m = modifiedDate.getMinutes();
    let hh = modifiedDate.getHours();
    let ss = modifiedDate.getSeconds();
    if (hh < 10)
        hh = `0${hh}`;
    if (m < 10)
        m = `0${m}`;
    if (ss < 10)
        ss = `0${ss}`;
    return `${mm}${separator}${dd}${separator}${yyyy} ${hh}${timeSeparator}${m}${timeSeparator}${ss}`;
}

/**
    * Convert Date to Time Format in HH:mm
    * @param {string|number|Date} date Date Format (default: "")
*/
export const get_HH_mm = (date = "") => {
    if (!date) 
        return "";
    const modifiedDate = new Date(date);
    let mm = modifiedDate.getMinutes();
    let HH = modifiedDate.getHours();
    return `${HH}:${mm}`;
}

export const get_HH_MM = (date = "") => {
    if (!date) 
        return "";
    const modifiedDate = new Date(date);
    var mm = modifiedDate.getMinutes();
    var HH = modifiedDate.getHours();
    if (HH < 10)
    HH = `0${HH}`;
    if (mm < 10)
        mm = `0${mm}`;
    return `${HH}:${mm}`;
}

/**
    * Checks if HTML element has scroll or overflow. Returns true if it has overflow
    * @param {EventTarget} event.target
*/
export const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight } = {}) => scrollHeight > clientHeight || scrollWidth > clientWidth;

/**
    * Transform file object to Base 64 string
    * @param {File} file
*/
export const toBase64 = (file = "") => { 
    if (!file) {
        toast.error("Corrupted File Object");
        return false;
    } else return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

/**
    * Gets type of Base 64 image string
    * @param {string} base64
*/
export const getBase64ImageType = (base64 = "") => {
    switch(base64 ? base64[0] : "") { 
        case "/": return "jpg";
        case "i": return "png";
        case "R": return "gif";
        case "U": return "webp";
        default: return null;
    }
};

/**
    * Format Number to K, M, B, or T
    * @param {Number} number
*/
export const formatNumber = (number = 0) => {
    if (number < 1e3) return number;
    if (number >= 1e3 && number < 1e6) return `${(number / 1e3).toFixed(1)}K`;
    if (number >= 1e6 && number < 1e9) return `${(number / 1e6).toFixed(1)}M`;
    if (number >= 1e9 && number < 1e12) return `${(number / 1e9).toFixed(1)}B`;
    if (number >= 1e12) return `${(number / 1e12).toFixed(1)}T`;
};

/**
    * Add commas to thousand base numbers
    * @param {Number} number
*/
export const numberWithCommas = (number = 0) => String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
    * Add commas to thousand base numbers
    * @param {string} text
    * @param {string} word
*/
export const countWordOccurences = (text = "", word = "") => text.split(word).length - 1;